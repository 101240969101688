import React from 'react';
import CourseProgress from './CourseProgress';
import { Grid, Box } from '@mui/material';
import { Course } from '../../utils/Types/DTO';
import { isEmpty } from 'lodash-es';


interface CoursesListProps {
  courses: Course[];
}

const CoursesList: React.FC<CoursesListProps> = ({ courses }) => {
  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Grid container spacing={3}>
        {!isEmpty(courses)&&(courses.map((course) => (
          <Grid item xs={12}  key={course.course_id}>
            <Box sx={{ boxShadow: 3 }}>
              <CourseProgress course={course} />
            </Box>
          </Grid>
        )))}
      </Grid>
    </Box>
  );
};

export default CoursesList;
