import React, { useState } from 'react';
import { Dialog, TextField, Button } from '@mui/material';

interface TransferDialogProps {
  open: boolean;
  onClose: () => void;
  existingManager: string;
  onTransfer: (newManager: string) => void;
}

const TransferDialog: React.FC<TransferDialogProps> = ({ open, onClose, existingManager, onTransfer }) => {
  const [newManager, setNewManager] = useState('');

  const handleTransfer = () => {
    onTransfer(newManager);
    // onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <div style={{ padding: '20px' }}>
        <TextField
          label="Existing Manager"
          value={existingManager}
          disabled
          fullWidth
          margin="normal"
        />
        <TextField
          label="New Manager Email"
          value={newManager}
          onChange={(e) => setNewManager(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button variant="contained" color="primary" onClick={handleTransfer}>
          Transfer
        </Button>
      </div>
    </Dialog>
  );
};

export default TransferDialog;
