import { styled, Stack } from "@mui/material"

export const TitleValueStack = styled(Stack)(() => ({
  padding: "20px 10px",
  alignItems: "center",
  textAlign: "center",
  justifyContent: "center",
  minWidth: "150px",
  minHeight: "110px",
  "&:hover": {
    cursor: "pointer",
  },
}))
