import { INotification } from "../../store/NotificationStore";


export const success = (message: string, autoHideDuration = 1500): INotification => ({
    message,
    type: 'success',
    autoHideDuration,
});

export const failed = (message: string, autoHideDuration = 3500): INotification => ({
    message,
    type: 'error',
    autoHideDuration,
});

export const warning = (message: string, autoHideDuration = 3500): INotification => ({
    message,
    type: 'warning',
    autoHideDuration,
});

export const info = (message: string, autoHideDuration = 1500): INotification => ({
    message,
    type: 'info',
    autoHideDuration,
});


