import { useState, useEffect } from "react"
import { getErrorMessage } from "../util"
import { organizationDTO } from "../Types/DTO"
import { useNotificationStore } from "../../store/NotificationStore"
import { getListOfOrganizations } from "../../API/services/UserServices"
import { failed } from "../../components/Notification/NotificationMessages"

const useOrganizations = () => {
  const [orgList, setOrgList] = useState<organizationDTO[]>([])
  const setNotification = useNotificationStore((state) => state.setNotification)

  useEffect(() => {
    const fetchOrgsList = async () => {
      try {
        const response = await getListOfOrganizations()
        if (response?.data) {
          setOrgList(response?.data)
        }
      } catch (error: any) {
        if (error?.response) {
          setNotification(failed(getErrorMessage(error)))
        }
      }
    }
    fetchOrgsList()
  }, [])

  return orgList
}

export default useOrganizations
