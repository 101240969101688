import { ReactNode } from "react"
import { Card, Stack, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { TitleValueStack } from "../../ComponentStyles/cardStyles"

interface SimpleCardWithTitleAndValueType {
  title: string | ReactNode
  value: number | string | ReactNode
  url: string
  bgColor?: string
  description?: string
  isSoftSkillCard?: boolean
  isSoftSkillsDB?: boolean
  styles?: any
}

export const SimpleCardWithTitleAndValue = ({
  title,
  value,
  url,
  bgColor,
  description = "",
  isSoftSkillsDB = false,
  isSoftSkillCard = false,
  styles = {},
}: SimpleCardWithTitleAndValueType) => {
  const navigate = useNavigate()

  return (
    <Card
      sx={{
        ...styles,
        m: 1,
        flexGrow: 1,
        boxShadow: 1,
        borderRadius: "22px",
        backgroundColor: bgColor,
      }}>
      <TitleValueStack onClick={() => navigate(url)}>
        <Typography sx={{ fontSize: "0.8rem" }}>{title}</Typography>
        {isSoftSkillCard ? (
          isSoftSkillsDB ? (
            <Typography sx={{ fontSize: "1.1rem", mt: "10px" }}>
              {description}
            </Typography>
          ) : (
            <Stack>
              <Stack sx={{ alignItems: "center", flexDirection: "row" }}>
                <Typography sx={{ fontSize: "0.9rem" }}> SCREEN:</Typography>
                <Typography sx={{ fontSize: "0.7rem" }}>
                  Integrity Awareness
                </Typography>
              </Stack>
              <Stack
                sx={{
                  alignItems: "center",
                  flexDirection: "row",
                }}>
                <Typography sx={{ fontSize: "0.9rem" }}>GROW: </Typography>
                <Typography sx={{ fontSize: "0.7rem" }}>
                  Emotional Intelligence
                </Typography>
              </Stack>
            </Stack>
          )
        ) : (
          <Typography sx={{ fontWeight: 350, fontSize: "2.4rem" }}>
            {value}
          </Typography>
        )}
      </TitleValueStack>
    </Card>
  )
}
