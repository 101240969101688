import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, TextField, Container, CssBaseline, Paper, Grid, makeStyles, Stack, Typography } from '@mui/material';
import TitleBanner from '../../TitleBanner/TitleBanner';
import { buyAssessment, getAllAssessmentTypes, getLicenseAvailable, getOrganizationdetailsById } from '../../../API/services';
import { warning, failed, success } from '../../Notification/NotificationMessages';
import { useNotificationStore } from '../../../store/NotificationStore';
import CountCard from '../../../HrDashboard/Components/CountCard';
import { organizationDTO } from '../../../utils/Types/DTO';
import { getErrorMessage } from '../../../utils/util';

export interface Product {
    id: string;
    name: string;
    assessmentCode: string;
}
export interface AvailableProductsDTO {
    id: string;
    assessmentTitle: string;
    assessmentCode: string;
    availableLicenses: number;
    organizationId: string;
}

const AddAssessment: React.FC = () => {
    
   
    let { orgId } = useParams<{ orgId: string }>();
    if (orgId) orgId = atob(orgId);
    else orgId = undefined;
   
    const [products, setProducts] = useState<Product[]>([]);
    const [organizationDetails, setOrganizationDetails] = useState<organizationDTO>();
    const [availableProducts, setAvailableProducts] = useState<AvailableProductsDTO[]>([]);
    const [selectedProductId, setSelectedProductId] = useState<string>("");
    const [licenseCount, setLicenseCount] = useState<number>(1); // Default to 1 license
    const setNotification = useNotificationStore((state) => state.setNotification);
    const [refresh, setRefresh]=useState(-1);

    useEffect(() => {
        const fetchAssessmentTypes = async () => {
            if (orgId) {
                const availableLicensesResponse = await getLicenseAvailable(orgId);
                const organizationDetailsResponse = await getOrganizationdetailsById(orgId);
                if (availableLicensesResponse.data) {
                    const availableLicenses: AvailableProductsDTO[] = availableLicensesResponse.data.esatLicenses;
                    setAvailableProducts(availableLicenses);
                }
                if(organizationDetailsResponse.data) setOrganizationDetails(organizationDetailsResponse.data);
            }
          

            const response = await getAllAssessmentTypes();
            if (response.data)
            {
                const fetchedProducts: Product[] = response.data;
                
                setProducts(fetchedProducts);
            }
         

        };
        fetchAssessmentTypes();
    }, [refresh]);

    const handleAllocate = async() => {
        if (!selectedProductId ) {
            handleError();
            return;
        }

        // Handle the allocation logic here.
        // console.log(`Allocating ${licenseCount} licenses of product with ID: ${selectedProductId} to organization with ID: ${orgId}`);
        if (orgId)
        {
            const payload = {
                "productName": products.find(o => o.assessmentCode === selectedProductId)?.name ?? "",
                "productId": selectedProductId,
                "count": licenseCount
            }
            try {

                const response = await buyAssessment(orgId, payload);
                
                if (response.data)
                {
                    setRefresh(Math.random());
                     setNotification(success('Assessment allocation successful'));
                    
                }


            } catch (error:any) {
                setNotification(failed(getErrorMessage(error.response.data)));
            }
        }
        else {
            setNotification(warning('No organization ID found'));
        }
       
    };
    const handleError=() =>{
        setNotification(warning('Please Select Assessment Type'));
    }


    return (
         <Container component="main">
            <CssBaseline />
            <TitleBanner title={"Add Assessment"} />
            {
                organizationDetails&&(
                <Paper elevation={3} style={{ padding: '20px', margin: '10px' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}  borderRadius={1.5}>
                                        <Stack spacing={2}>
                                            <Typography variant="h5">
                                                <strong>Organization Name:</strong> {organizationDetails.organizationName}
                                            </Typography>
                                            {/* <Typography variant="body1">
                                                <strong>Birth Year Range:</strong> {assessmentData.yearOfBirth}
                                            </Typography>
                                            <Typography variant="body1">
                                                <strong>Phone:</strong> {assessmentData.phone}
                                            </Typography> */}
                                        </Stack>
                                    </Grid>
                                </Grid>
                    </Paper>
                )
            }
            
            <Paper elevation={3} style={{ padding:'20px', margin:'10px' }}>
                <Grid container spacing={3}>
                    {
                        products && (
                            products.map((product) => (
                                <>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <CountCard count={availableProducts.find(o => o.assessmentCode === product.assessmentCode)?.availableLicenses??0} bottomText={product.name} />
                                    </Grid>

                                </>
                            ))

                        )
                    }
                </Grid>

            </Paper>
            <Paper elevation={3} style={{ padding: '20px', margin: '10px' }}> 
                
                <Grid gap={2} container>

                    <Grid item>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Select Assessment</FormLabel>
                            <RadioGroup
                                value={selectedProductId}
                                onChange={(e) => setSelectedProductId(e.target.value)}
                            >
                                {products.map((product) => (
                                    <FormControlLabel
                                        key={product.assessmentCode}
                                        value={product.assessmentCode}
                                        control={<Radio />}
                                        label={product.name}
                                        sx={{
                                            '.MuiFormControlLabel-label': {
                                                fontSize: '18px',      // Larger font size
                                                fontWeight: 'bold',   // Bold text
                                                color: 'darkblue',    // Change font color
                                                fontFamily: 'Arial',  // Custom font
                                                marginLeft: '10px',   // Add spacing as needed
                                            }
                                        }}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                        <TextField
                            label="Count"
                            type="number"
                            value={licenseCount}
                            onChange={(e) => setLicenseCount(Math.max(1, Number(e.target.value)))} // Ensure it's always at least 1
                            variant="outlined"
                            style={{ marginTop: 20 }}
                        />
                    </Grid>
                    <Grid  item>
                       
                        <Button variant="contained" onClick={handleAllocate} style={{ marginTop: 20 }}>
                            Allocate
                        </Button>
                    </Grid>
                    
                </Grid>
            

           
            </Paper>
        </Container>
    );
}

export default AddAssessment;

