import React, { useEffect, useState } from 'react';
import { CircularProgress, Box, LinearProgress } from '@mui/material';
import useAxiosLoader from './useAxiosLoader';
import { hexColors } from '../utils/util';

const GlobalLoader: React.FC = () => {

    const loading= useAxiosLoader();

    //   // Render the loader or null based on the isLoading state
    //   return  loading ? (
    //     <Box
    //         sx={{
    //             position: 'fixed',
    //             top: 0,
    //             left: 0,
    //             width: '100%',
    //             height: '100%',
    //             display: 'flex',
    //             justifyContent: 'center',
    //             alignItems: 'center',
    //             backgroundColor: 'rgba(255, 255, 255, 0.5)', // Semi-transparent white background
    //             zIndex: (theme) => theme.zIndex.modal + 1
    //         }}
    //     >
    //         <CircularProgress />

    //         {/* <LinearProgress
    //         sx={{
    //             zIndex: 1,
    //             position: 'relative',
    //             top: 0,
    //             height: 5,
    //         }}
    //         color="primary"
    //     /> */}
    //     </Box>
    // ) : <></>
    const [currentColor, setCurrentColor] = useState(hexColors[0]);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const changeColor = () => {
            const nextIndex = (index + 1) % hexColors.length;
            setCurrentColor(hexColors[nextIndex]);
            setIndex(nextIndex);
        };

        const interval = setInterval(changeColor, 1600); // Change color every second

        return () => clearInterval(interval);
    }, [index]);

    return   loading ? (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(240,245,244, 0.8)',
                zIndex: (theme) => theme.zIndex.modal + 1
            }}
        >
            <CircularProgress  style={{ color: currentColor }} 
            thickness={5} // Increased thickness  
            />
        </Box>
        ) : <></>;

    
};


export default GlobalLoader;
