import { Stack } from "@mui/material"
import { useSuperAdminStore } from "../store/SuperAdminStore"
import CandidatesList from "../HrDashboard/CandidateScores/CandidatesList"
import { SimpleCardWithTitleAndValue } from "../components/Assesment/components/SimpleCardWithTitleAndValue"

export const DashboardWithCardAndList = ({ showViewCourseAction }: any) => {
  const cardData = useSuperAdminStore((state) => state?.cardInfo)
  const { title, score, bgColor, isSoftSkillsCard } = cardData || {}
  return (
    <Stack sx={{ alignItems: "center", gap: 3 }}>
      <SimpleCardWithTitleAndValue
        title={title}
        value={score}
        bgColor={bgColor}
        url="#"
        isSoftSkillCard={isSoftSkillsCard}
      />
      <CandidatesList showViewCourseAction={showViewCourseAction} />
    </Stack>
  )
}
