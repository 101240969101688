// BarChart.tsx
import React, { FC, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
// import ApexOptions from 'react-apexcharts';
import { ApexOptions } from "apexcharts";
import { capitalizer, generateRandomColor, hexColors } from "../../../utils/util";
import { Container, Typography } from "@mui/material";
import { scoreCardType } from "../../../utils/Types/DTO";

export interface barGraphProps {
  title: string;
  scoreData?: scoreCardType[];
}

const BarChart: FC<barGraphProps> = ({ title, scoreData }) => {
  const [barChartData, setBarChartData] = useState<{ skillName: string; score:number}[]>([]);
  const data = [90, 59, 80, 30, 50, 23, 45, 56, 33, 10, 34];

  useEffect(() => {
    const barChartDataGen = () => {
      const data: { skillName: string; score: number }[] =[]
     scoreData && scoreData.map((item) => data.push({ skillName :capitalizer(item.name),score:item.score}))
      setBarChartData(data);
    };
    barChartDataGen();

  }, []);
  
  const categories = [
    "Integrity Awareness",
    "Accountability",
    "Effective Learning",
    "Communication",
    "Emotional Intelligence",
    "Critical Thinking",
    "Problem Solving",
    "Conflict Resolution",
    "Influence & Persuasion",
    "Self-Confidence",
    "Stress Management",
  ];
  const randomGenerator=()=>{
    const value =Math.random();
        return{
            score: Math.round(value*100),
            result: value*100 < 0.5*100
        }};
  const colors = barChartData.map(() => generateRandomColor());
  const options: ApexOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        dataLabels: {
          position: "top", // top, center, bottom
        },
        distributed: true,
      },
    },
    xaxis: {
      categories: barChartData.map((item) => (item.skillName)),
      labels: {
        show: false  // This will ensure the skill names are displayed
      }
    },
    colors: hexColors,
    
  };
  console.log(data.map(() => generateRandomColor()));
  const series = [
    {
      name: "Score",
      data: barChartData.map((item)=>(item.score)),
    },
  ];

  return (
    <Container component={"main"}>
      <Typography align="center" variant="body2" fontSize={25} sx={{ opacity: 0.8 }}>{title}</Typography>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </Container>
  );
};

export default BarChart;
