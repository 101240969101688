import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes';
import { authInterceptor } from './API/authInterceptor';
import { PollingHandler } from './utils/pollingHandler';
import CustomNotification from './components/Notification/CustomNotification';
import GlobalLoader from './API/GlobalLoader';
// import GlobalLoader from './API/useAxiosLoader';

function App() {
  authInterceptor();
  return (
    <>
    <GlobalLoader/>
    <Router>
     
      <PollingHandler />
      <CustomNotification/>
      <Routes />
       </Router>
       </>
  );
}

export default App;
