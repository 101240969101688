import axios from './axios';
import { getAuthToken, getSessionToken, invalidateTokens } from '../utils/util';
import publicAllowedUrlPaths from '../routes/publicPaths';




const Axios: any = axios;

export const authInterceptor = () => {
  Axios.interceptors.request.use(
    function (config: any) {
      const authCookie = getAuthToken();
      const sessionID = getSessionToken();
      // if (isEmpty(config.headers)) {
      //   config.headers = {};
      //   config.headers['Content-Type'] = 'application/json';
      // }
      if (authCookie) {
        config.headers.Authorization = 'Bearer ' + authCookie;
      }
      if (sessionID) {
        config.headers.sessionID = sessionID;
      }
      config.withCredentials = true;
      // config.headers["Access-Control-Allow-Origin"] = "*";
      return config;
    },
    function (error: any) {
      return Promise.reject(error);
    }

  );

  Axios.interceptors.response.use(
    function (response: any) {
      // if (response && response.headers && !isEmpty(response.headers['x-workspace-token'])) {
      //   setWorkspaceToken(response.headers['x-workspace-token']);
      // }

      return response;
    },
    function (error: any) {

      if (
        !publicAllowedUrlPaths.includes(window.location.pathname)
      ) {

        if (error.response?.status === 401) {
          // setNavURL(window.location.pathname);
          invalidateTokens();
        }
      }
      return Promise.reject(error);
    }
  );
};

