import { isEmpty } from 'lodash-es';
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import MenuDrawer from "../components/AppLayout/AppLayout";
import Footer from "../components/AppLayout/BottomBar/Footer";
import { getAuthToken, getRoleToken } from "../utils/util";
import { ProtectedRoutes, HRProtectedRoutes, SuperAdminProtectedRoutes,UserProtectedRoutes } from "./routes";
import { PublicRoutes } from "./public";
import { useEffect } from "react";
import publicAllowedUrlPaths from "./publicPaths";
import { PollingHandler } from '../utils/pollingHandler';
import GlobalLoader from '../API/useAxiosLoader';
import UserContextProvider from '../context/UserContext';

const Routes = () => {
    const location = useLocation();
    const authToken = getAuthToken();
    const role = getRoleToken();
    const navigate = useNavigate();

    console.log(role);
    const HRRoutes = useRoutes([...HRProtectedRoutes]);
    const OtherRoutes = useRoutes([...ProtectedRoutes]);
    const CandidateRoutes = useRoutes([...UserProtectedRoutes]);
    const superAdminRoutes = useRoutes([...SuperAdminProtectedRoutes]);
    const element = useRoutes([...PublicRoutes]);

    const pattern = /^\s*\/change-password\/[\w.-]+\s*$/;
    useEffect(() => {
        if (!pattern.test(location.pathname)&&!publicAllowedUrlPaths.includes(location.pathname) && isEmpty(authToken)) {
            console.log('No session Found in Index.tsx routes file');
            navigate('/login');
        }

    }, [location.pathname]);

    return (

        <>
        {/* <GlobalLoader/> */}
            {!isEmpty(authToken) ? (
                <UserContextProvider>

                    {

                        role == 'HR' && (

                            HRRoutes && (
                                <MenuDrawer child={HRRoutes} />

                            )
                        )
                    }

                    {

                        role == 'CANDIDATE' && (

                            CandidateRoutes && (
                                <MenuDrawer child={CandidateRoutes} />

                            )
                        )
                    }

                    {

                        role == 'SUPER_USER' && (

                            superAdminRoutes && (
                                <MenuDrawer child={superAdminRoutes} />

                            )
                        )
                    }

                    {

                        role == 'SUPERVISOR' && (

                            OtherRoutes && (
                                <MenuDrawer child={OtherRoutes} />

                            )
                        )
                    }
                    {

                        role == 'ADMINISTRATOR' && (

                            OtherRoutes && (
                                <MenuDrawer child={OtherRoutes} />

                            )
                        )
                    }

                </UserContextProvider>

            ) : (
                element
            )
            }
        </>
    );

};
export default Routes;