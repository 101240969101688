import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getLoggedInUser } from '../../../utils/util';
import { consumeLicenses } from '../../../API/services';
import { useNotificationStore } from '../../../store/NotificationStore';
import { failed, success, warning } from '../../Notification/NotificationMessages';
import { Paper, Typography, List, ListItem, ListItemText, Divider, Container, Button } from '@mui/material';
import TitleBanner from '../../TitleBanner/TitleBanner';
import { useUserContext } from '../../../context/UserContext';

const ReviewAndSubmitComponent: React.FC = () => {
    const loggedInUserDetailsResponse = useUserContext();

    const setNotification = useNotificationStore((state) => state.setNotification);
    const location = useLocation();
    const navigate = useNavigate();
    const respondents = location.state?.respondents;
    const assessmentId = location.state?.assessmentId;
    const loggedInUser = getLoggedInUser();
    const orgMap = loggedInUserDetailsResponse?.organizationMap; 
    const firstOrgId = orgMap ? Object.keys(orgMap)[0] : null;

    const handleSubmit = async () => {
        try {
            if (loggedInUserDetailsResponse) {
                const payload = {
                    buyerEmail: loggedInUser ?? "",
                    managerEmail: loggedInUser ?? "",
                    respondents: respondents,
                    lineItems: [
                        {
                            group:firstOrgId,
                            assessmentId: assessmentId
                        }
                    ]
                };
                // Make the backend request with the constructed payload
                const response = await consumeLicenses(payload);
                setNotification(success("Your request processed successfully"));
                navigate('/assessment/assign');
            }

        } catch (error:any) {
            if (error.response.status==402) {
                setNotification(warning("Require more licenses for the operation."));
                setNotification(failed(getErrorMessage(error.response.data)));
            }
            else {
                setNotification(failed(getErrorMessage(error.response.data)));
            } 
        }
       
    };

    return (
        <Container>
            <TitleBanner title={"Review & Submit"} />
            {/* Render the data for review */}
            <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
                <Typography variant="h6">Admin Details:</Typography>
                <Typography><strong>Email:</strong> {loggedInUser}</Typography>
                <Typography><strong>Assessment Code:</strong> {assessmentId}</Typography>
            </Paper>

            {/* Display respondents' details */}
            <Paper elevation={3} style={{ padding: '16px' }}>
                <Typography variant="h6">Respondents:</Typography>
                <List>
                    {respondents.map((res:any, index:any) => (
                        <div key={index}>
                            <ListItem>
                                <ListItemText primary={res.firstname + ' ' + res.lastname} secondary={res.email} />
                            </ListItem>
                            {index !== respondents.length - 1 && <Divider />}
                        </div>
                    ))}
                </List>
            </Paper>
            <Button variant='contained' onClick={handleSubmit}>Submit</Button>
        </Container>
    );
};

export default ReviewAndSubmitComponent;
function getErrorMessage(data: any): any {
    throw new Error('Function not implemented.');
}

