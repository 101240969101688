export const URLS = {
  base: "/",
  edit_entity: "/entity/edit",
  org: "/org",
  assessment: {
    store_test: "/store-test",
    dashboard: "/assessment/dashboard",
    total_applicants: "/assessment/total-applicants",
    total_employees: "/assessment/total-employees",
    grow_assessments: "/assessment/grow-assessments",
    screen_assessment_details: "/screen-assessment-details",
    avg_assessment_score: "/assessment/avg-assessment-score",
    avg_grow_assessment_score: "/assessment/avg-grow-assessment-score",
    avg_screen_assessment_score: "/assessment/avg-screen-assessment-score",
  },
}
