import { Grid, Card, CardContent, Typography, CardMedia, Box, Container, CssBaseline, Button } from "@mui/material";
import logo from '../../../assets/noshadow.png';
import React from "react";
import { capitalizer, formatIsoDate, getAssessmentName } from "../../../utils/util";
import { LicenseDTO, SubscriptionDTO } from "../../../utils/Types/DTO";
import TitleBanner from "../../TitleBanner/TitleBanner";


interface LicenseDetailsCardsProps {
    esatLicenses: LicenseDTO[],
    esatSubscriptions: SubscriptionDTO[],
    onCancel: (subscriptionId:string) => void
};

const LicenseDetailsCards = ({ esatLicenses, esatSubscriptions,onCancel }: LicenseDetailsCardsProps) => {
    return (
        <Container component="main">
            <CssBaseline />
            <TitleBanner title="Subscription Details" />

            <Grid alignItems={"center"} marginTop={2} direction={"row"} container rowGap={2} spacing={2}>
                <Grid xs={6} item container direction={"row"} >
                    {esatSubscriptions.filter((item)=>item.status=='active').map((subscription, index) => (
                        <React.Fragment key={index}>
                            
                                <Grid  direction={"row"} container spacing={2} sx={{ maxWidth: 250, margin: 'auto', boxShadow: 3, borderRadius: 2, overflow: 'hidden' }}>
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
                                        <img src={logo} alt="Logo" style={{ height: '40px', width: '200px' }} />
                                    </Grid>
                                    <Grid item xs={12} sx={{ backgroundColor: '#D1E9FC', p: 1, }}>
                                        <Typography fontSize={26} fontWeight={600} sx={{ color: '#061B64', textAlign: 'center' }} variant="h4">{capitalizer(getAssessmentName(subscription.productId))}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ p: 1, backgroundColor: subscription.status === 'active' ? '#d1fcd7' : '#fcd1d1' }}>
                                        <Typography fontSize={26} fontWeight={600} variant="h5" sx={{ color: '#061B64', textAlign: 'center' }}>
                                            {capitalizer(subscription.status)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ backgroundColor: '#D1E9FC', p: 1, }}>
                                        <Typography fontWeight={600} fontSize={15} sx={{ color: '#061B64', textAlign: 'center' }} variant="h5" >
                                            Start Date
                                            <Typography fontWeight={600} fontSize={15} sx={{ color: '#061B64', textAlign: 'center' }} variant="h5" >{formatIsoDate(subscription.startDate)}</Typography>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ backgroundColor: '#D1E9FC', p: 1, }}>
                                        <Typography fontWeight={600} fontSize={15} sx={{ color: '#061B64', textAlign: 'center' }} variant="h5" >
                                            End Date
                                            <Typography fontWeight={600} fontSize={15} sx={{ color: '#061B64', textAlign: 'center' }} variant="h5" >{formatIsoDate(subscription.endDate)}</Typography>
                                        </Typography>
                                    </Grid>
                                    {
                                        subscription.cancelAtPeriodEnd ? (
                                            <Grid item xs={12} sx={{ backgroundColor: '#f7fcd1', p: 1, }}>
                                                <Typography fontWeight={600} fontSize={15} sx={{ color: '#061B64', textAlign: 'center' }} variant="h5" >
                                                    Cancellation Requested
                                                </Typography>
                                            </Grid>
                                        ):(<Grid item xs={12} sx={{ backgroundColor: '#D1E9FC', p: 1, }}>
                                        <Button variant="contained" onClick={()=>onCancel(subscription.subscriptionId)}>Cancel</Button>
                                    </Grid>)
                                    }
                                    
                                </Grid>
                            
                        </React.Fragment>
                    ))}
                </Grid>
                <Grid xs={6} item container >
                    <Grid direction={"column"} container rowGap={2} sx={{ margin: 'auto', }}>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 1 }}>
                            <img src={logo} alt="Logo" style={{ height: '40px', width: '200px' }} />
                        </Grid>
                        {esatLicenses.map((license, index) => (
                            <React.Fragment key={index}>

                                <Grid container direction={"column"} rowGap={2} sx={{ p: 1, maxWidth: 250, boxShadow: 3, borderRadius: 2, overflow: 'hidden', justifyContent: 'center' }} item>
                                    <Grid sx={{ backgroundColor: '#D1E9FC', p: 1, }}>
                                        <Typography fontWeight={600} sx={{ color: '#061B64', textAlign: 'center' }} variant="h4">{capitalizer(license.assessmentTitle)}</Typography>
                                    </Grid>

                                    <Grid sx={{ justifyContent: 'center' }} container direction={"row"}>
                                        <Typography fontWeight={600} fontSize={17.5} sx={{ color: '#061B64', textAlign: 'center' }} variant="h5" >
                                            Used
                                        </Typography>
                                        <Grid sx={{ justifyContent: 'center' }} item xs={3}>
                                            <Typography fontWeight={700} fontSize={17.5} sx={{ color: '#061B64', textAlign: 'center' }} variant="body1" >{license.totalLicensesUsed} / {license.availableLicenses}</Typography>
                                        </Grid>

                                    </Grid>
                                </Grid>


                            </React.Fragment>
                        ))}
                    </Grid>
                </Grid>
            </Grid>




        </Container>
    );
};
export default LicenseDetailsCards;