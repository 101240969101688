import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Button,
  Box,
  Stack,
} from "@mui/material"
import { useSuperAdminStore } from "../../store/SuperAdminStore"

const OptionDropDown = ({
  options,
  handleOnChange,
  selectedOption,
  setSelectedOption,
  onSearchClickCB,
  showSearchButton = true,
}: any) => {
  const orgList = useSuperAdminStore((state) => state?.orgList)

  return (
    <Stack gap={1}>
      <FormControl fullWidth sx={{ width: 210 }}>
        <InputLabel>Filter by: Organization</InputLabel>
        <Select
          value={selectedOption}
          label="Filter by: Organization"
          onChange={handleOnChange}>
          {(options || orgList)?.map((option: any) => (
            <MenuItem key={option?.accessKey} value={option?.organizationName}>
              <Typography
                sx={{
                  width: "100%",
                  overflow: "clip",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}>
                {option?.organizationName}
              </Typography>
            </MenuItem>
          ))}
          <Box sx={{ display: "flex", justifyContent: "flex-end", mr: "2vw" }}>
            <Button
              onClick={() => setSelectedOption("")}
              variant="outlined"
              disabled={!selectedOption}>
              clear
            </Button>
          </Box>
        </Select>
      </FormControl>
      {showSearchButton && (
        <Button
          variant="outlined"
          size="small"
          sx={{
            width: "fit-content",
          }}
          onClick={onSearchClickCB}>
          Search
        </Button>
      )}
    </Stack>
  )
}

export default OptionDropDown
