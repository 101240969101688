import { Card, Typography } from "@mui/material";
import { isEmpty } from "lodash-es";
import { title } from "process";
import { fNumber, fShortenNumber } from "../../utils/numberFormater";

interface ScoreCardType{
  count:number;
  bgcolor?:string;
  onClickHandler?:()=>void;
  bottomText?:string;
  clickable?:boolean;

}

const CountCard =({count, bgcolor='#D1E9FC',onClickHandler=()=>{},bottomText='',clickable}:ScoreCardType)=>{
    return(
        <Card
      sx={{
        py: 3,
        boxShadow: 0,
        textAlign: 'center',
        bgcolor: bgcolor,
        color:'#061B64',
        cursor:clickable?'pointer':'default',
        
      }}
      onClick={()=>onClickHandler()}
    >
        <Typography variant="h3">{fNumber(count)}</Typography>
{
  !isEmpty(bottomText)&&(
    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
  {bottomText}
</Typography>
  )
}

</Card>
    );
};

export default CountCard;