import { Box, Stack } from "@mui/material"
import { URLS } from "../constants"
import { useSuperAdminStore } from "../store/SuperAdminStore"
import { SimpleCardWithTitleAndValue } from "../components/Assesment/components/SimpleCardWithTitleAndValue"

export const DashboardWithTwoCards = () => {
  const { setCartInfo, cartInfo } = useSuperAdminStore((state) => ({
    setCartInfo: state?.setCardInfo,
    cartInfo: state?.cardInfo,
  }))

  return (
    <Stack
      sx={{
        height: "80vh",
        maxWidth: "80%",
        margin: "0 auto",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <Stack sx={{ gap: 4 }}>
        {cartInfo?.isSoftSkillsCard ? (
          <Stack flexDirection={"row"}>
            <SimpleCardWithTitleAndValue
              value={0}
              bgColor="#ADD8E6"
              isSoftSkillsDB={true}
              isSoftSkillCard={true}
              description="Integrity Awareness"
              title={`${cartInfo?.title} SCREEN`}
              url={URLS?.assessment?.screen_assessment_details}
              styles={{ minWidth: "210px", padding: "20px" }}
            />
            <SimpleCardWithTitleAndValue
              value={0}
              bgColor="#90EE90"
              isSoftSkillsDB={true}
              isSoftSkillCard={true}
              description="Emotional Intelligence"
              title={`${cartInfo?.title} GROW`}
              url={URLS?.assessment?.screen_assessment_details}
              styles={{ minWidth: "210px", padding: "20px" }}
            />
          </Stack>
        ) : (
          <Stack flexDirection={"row"}>
            <Box
              onClick={() =>
                setCartInfo({
                  title: "Average SCREEN Assessment Score",
                  score: 10,
                  bgColor: "#ADD8E6",
                  isSoftSkillsCard: false,
                })
              }>
              <SimpleCardWithTitleAndValue
                value={87}
                bgColor="#ADD8E6"
                url={URLS?.assessment?.avg_screen_assessment_score}
                title={
                  <p>
                    Average <strong>SCREEN</strong> Assessment Score
                  </p>
                }
              />
            </Box>
            <Box
              onClick={() =>
                setCartInfo({
                  title: "Average GROW Assessment Score",
                  score: 20,
                  bgColor: "#90EE90",
                  isSoftSkillsCard: false,
                })
              }>
              <SimpleCardWithTitleAndValue
                value={76}
                bgColor="#90EE90"
                url={URLS?.assessment?.avg_grow_assessment_score}
                title={
                  <p>
                    Average <strong>GROW</strong> Assessment Score
                  </p>
                }
              />
            </Box>
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}
