import { useEffect, useState } from "react";
import EditProfile from "./EditProfile";
import ViewProfile from "./ViewProfile";
import { UserDetailsDTO } from "../../utils/Types/DTO";
import {  updateUserDetails } from "../../API/services";
import { failed, success } from "../Notification/NotificationMessages";
import { useNotificationStore } from "../../store/NotificationStore";
import { getErrorMessage, getLoggedInUser } from "../../utils/util";
import { useUserContext } from "../../context/UserContext";

const MyProfile =()=>{
    const loggedInUser = useUserContext();
    const getLoggedInUserEmail = getLoggedInUser();
    const setNotification = useNotificationStore((state) => state.setNotification);
    const [user, setUser] = useState<UserDetailsDTO>(loggedInUser as UserDetailsDTO);
    const [editing, setEditing] = useState(false);

      const handleEdit = () => {
        setEditing(true);
      };
    
      const handleSave = (editedUser:UserDetailsDTO) => {
        setUser(editedUser);
        setEditing(false);
        updateUser(editedUser);
      };

      const updateUser=async(updatedUser:UserDetailsDTO)=>{

        if(updatedUser){
            const tempData= updatedUser;
            tempData.fullName= `${tempData.firstName} ${tempData.lastName} `;
            try {
                const updateDetailsResponse = await updateUserDetails(tempData);
                if(updateDetailsResponse.data)
                {
                    setNotification(success('Profile updated Successfully'));
                }
            } catch (e:any) { 
              setNotification(failed(getErrorMessage(e.response.data)));
         
            }
      }
    };
    
    return(
    <>
    
    {user && (editing ? (
        <EditProfile user={user} onSave={handleSave} />
      ) : (
        <ViewProfile user={user} onEdit={handleEdit} />
      ))}
    </>
    );
};
export default MyProfile;