const publicAllowedUrlPaths = [
    '/',
    '/entity/register/',
    '/entity/register/',
    '/login',
    '/forgot-password',
    '/change-password/',
    '/reset-password',
  ];
  
  export default publicAllowedUrlPaths;
  