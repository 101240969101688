import React, { FC, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { capitalizer, generateRandomColor, hexColors } from "../../../utils/util";
import { Container, Typography } from "@mui/material";
import { scoreCardType } from "../../../utils/Types/DTO";

export interface pieChartProps {
    title: string;
    scoreData?: scoreCardType[];
}

const PieChart: FC<pieChartProps> = ({ title, scoreData }) => {
    const [pieChartData, setPieChartData] = useState<{ skillName: string; score: number }[]>([]);

    useEffect(() => {
        const pieChartDataGen = () => {
            const data: { skillName: string; score: number }[] = [];
            scoreData && scoreData.map((item) => data.push({ skillName: capitalizer(item.name), score: item.score }));
            setPieChartData(data);
        };
        pieChartDataGen();
    }, []);

    const colors = pieChartData.map(() => generateRandomColor());

    const options: ApexOptions = {
        labels: pieChartData.map((item) => item.skillName),
        colors: hexColors,
        legend: {
            show: true,
            position: 'bottom',
        },
        chart: {
            toolbar: {
              show: false
            }
          }
    };

    const series = pieChartData.map((item) => item.score);

    return (
        <Container component={"main"}>
            <Typography align="center" variant="body2" fontSize={25} sx={{ opacity: 0.8 }}>{title}</Typography>
            <ReactApexChart
                options={options}
                series={series}
                type="pie"
                
                height={350}
            />
        </Container>
    );
};

export default PieChart;
