import { LinearProgress } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import axios from './axios';


// const ax: any = axios;

// const useAxiosLoader = () => {
//     const [counter, setCounter] = useState(0);

//     const inc = useCallback(() => setCounter((counter) => counter + 1), [setCounter]);
//     const dec = useCallback(() => setCounter((counter) => counter - 1), [setCounter]);

//     const interceptors = useMemo(
//         () => ({
//             request: (config: any) => {
//                 inc();
//                 return config;
//             },
//             response: (response: any) => {
//                 dec();
//                 return response;
//             },
//             error: (error: any) => {
//                 dec();
//                 return Promise.reject(error);
//             },
//         }),
//         [inc, dec]
//     );

//     useEffect(() => {
//         const interceptorReq = ax.interceptors.request.use(interceptors.request, interceptors.error);
//         const interceptorRes = ax.interceptors.response.use(interceptors.response, interceptors.error);
//         return () => {
//             ax.interceptors.request.eject(interceptorReq);
//             ax.interceptors.request.eject(interceptorRes);
//         };
//     }, [interceptors]);

//     return [counter > 0];
// };


const useAxiosLoader = () => {
    const [counter, setCounter] = useState(0);
    const [load, setLoad] = useState(true);
    const inc = () => setCounter(c => c + 1);
    const dec = () => setCounter(c => Math.max(c - 1, 0)); // Prevent negative count

    useEffect(() => {
        const interceptorReq = axios.interceptors.request.use(config => {
            if(config.url != 'auth/refreshToken'){
                inc();
            }
            return config;
        }, error => {
            dec();
            return Promise.reject(error);
        });

        const interceptorRes = axios.interceptors.response.use(response => {
            dec();
            return response;
        }, error => {
            dec();
            return Promise.reject(error);
        });

        // Cleanup
        return () => {
            axios.interceptors.request.eject(interceptorReq);
            axios.interceptors.response.eject(interceptorRes);
        };
    }, []);

    return counter > 0;
};

export default useAxiosLoader;

// const GlobalLoader = () => {
//     const [loading] = useAxiosLoader();
//     return loading ? (
//         <LinearProgress
//             sx={{
//                 zIndex: (t) => t.zIndex.modal + 3,
//                 position: 'relative',
//                 top: 0,
//                 height: 5,
//             }}
//             color="primary"
//         />
//     ) : (
//         <></>
//     );
// };

// export default GlobalLoader;
