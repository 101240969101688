import { Box, Button, Chip, Container, CssBaseline, FormControl, Grid, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import TableComponent from "../../components/TableComponent/Table";
import { useEffect, useState } from "react";
import { getOrganizationdetailsById, getSupervisors, getSupervisorsByOrgId } from "../../API/services";
import { getCookieByName, getLoggedInUser, getPermissions, getRoleToken, hasPermissions } from "../../utils/util";
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from "react-router-dom";
import TitleBanner from "../../components/TitleBanner/TitleBanner";
import { filter, isEmpty } from "lodash-es";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { organizationDTO } from "../../utils/Types/DTO";
import NoDataCard from "../../components/NoDataCard/NoDataCard";

interface supervisorListType {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    tag: string;
    numberOfCandidates: number;
    orgDetails: any;
}
type props = {
    bgColor: string;
};
const StyleChip = styled(Chip)(({ bgColor }: props) => ({
    backgroundColor: bgColor,
    borderRadius: 3,
}));
const SupervisorListing = () => {
    let { organizationId } = useParams<{ organizationId: string }>();
    organizationId = atob(organizationId ?? "");
    const getRole=getRoleToken();
    const permissions=(getPermissions()??"").split(",")??[''];
    const navigate = useNavigate();
    const getLoggedInUserEmail = getLoggedInUser();
    const [organizationDetails, setOrganizationDetails] = useState<organizationDTO>();
    const [supervisorList, setSupervisorList] = useState<supervisorListType[]>();
    const [filter, setFilter] = useState('');
    const [organizations, setOrganizations] = useState<string[]>([]);
    useEffect(() => {
        const getSupervisorsList = async () => {

            if (organizationId && !isEmpty(organizationId))
            {
                const organizationDetailsResponse = await getOrganizationdetailsById(organizationId);
                if(organizationDetailsResponse.data) setOrganizationDetails(organizationDetailsResponse.data);
                const response = await getSupervisorsByOrgId(organizationId);
                if (response.data.list) {
                    setSupervisorList(response.data.list);
                }
                }
           else if (getLoggedInUserEmail) {
                const response = await getSupervisors(getCookieByName('userId') ?? '');
                if (response.data.list) {
                    setSupervisorList(response.data.list);
                }
            }
        };
        getSupervisorsList();
    }, []);
    useEffect(() => {
        if(supervisorList){
        // Extracting unique organizations
        const uniqueOrgs = Array.from(new Set(supervisorList.map(entry => Object.keys(entry.orgDetails)[0])));
        setOrganizations(uniqueOrgs);
        }
    }, [supervisorList]);

    const handleFilterChange = (event: SelectChangeEvent<string>) => {
        setFilter(event.target.value);
    };
    // Filter entries by the selected organization
    const filteredEntries = supervisorList&&supervisorList.filter(entry => Object.keys(entry.orgDetails)[0] === filter || filter === '');
    return (
        <Container component="main">
            <CssBaseline />
            {/* <TableComponent typeOfUser="supervisor" />
             */}
            
            <TitleBanner title={"Supervisors Listing"} rightChild={
                getRole=='ADMINISTRATOR'&&(
                <Stack style={{ cursor: "pointer" }} onClick={() => { navigate(`/supervisors/add`) }} alignItems={"center"} direction={"row"} spacing={1}>
                    <PersonAddIcon style={{ color: 'white' }} />
                    <Typography align="center" color={"white"} variant="h1" fontSize={18} sx={{ opacity: 1 }}>Add Supervisor</Typography>

                </Stack>)} />

               
                {
                organizationDetails&&(
                <Paper elevation={3} style={{ padding: '20px', marginTop: '10px' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}  borderRadius={1.5}>
                                        <Stack spacing={2}>
                                            <Typography variant="h5">
                                                <strong>Organization Name:</strong> {organizationDetails.organizationName}
                                            </Typography>
                                            {/* <Typography variant="body1">
                                                <strong>Birth Year Range:</strong> {assessmentData.yearOfBirth}
                                            </Typography>
                                            <Typography variant="body1">
                                                <strong>Phone:</strong> {assessmentData.phone}
                                            </Typography> */}
                                        </Stack>
                                    </Grid>
                                </Grid>
                    </Paper>
                )
            }
            { getRole=='SUPER_USER'&&(
            <Box mt={2} mb={2}>
                <Stack direction="column">
                    <FormControl fullWidth>
                        <InputLabel id="org-select-label">Organization</InputLabel>
                        <Select
                            labelId="org-select-label"
                            id="org-select"
                            value={filter}
                            label="Organization"
                            onChange={handleFilterChange}
                        >
                            <MenuItem value="">
                                <em>All</em>
                            </MenuItem>
                            {organizations.map((org, index) => (
                                <MenuItem key={index} value={org}>{org}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
            </Box>
            )}
            {
                !isEmpty(filteredEntries)?(
                        <TableContainer style={{ marginTop: '10px' }} component={Paper}>
                    <Table stickyHeader>
                        <TableHead><TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Assigned Users</TableCell>
                            <TableCell>Designation</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow></TableHead>
                        <TableBody>
                            {
                                filteredEntries && (
                                    filteredEntries.map((supervisor) => (
                                        <TableRow key={supervisor.id}>
                                            <TableCell>{supervisor.firstName} {supervisor.lastName}</TableCell>
                                            <TableCell>{supervisor.email}</TableCell>
                                            <TableCell>{supervisor.numberOfCandidates}</TableCell>
                                            <TableCell><StyleChip label={supervisor.tag} bgColor={"#f7fcd1"} /></TableCell>
                                            <TableCell align="right">
                                            <Tooltip title="Assign Applicants">
                                                <Button
                                                    sx={{ marginRight: 2 }}
                                                    variant="contained"
                                                    size="small"
                                                    onClick={() => navigate(`/assessment/assign`)}
                                                    disabled={(hasPermissions('addDr',permissions)||getRole=='ADMINISTRATOR')?false:true}
                                                >
                                                    Assign Applicants
                                                </Button>
                                                </Tooltip>
                                                <Button
                                                    sx={{ marginRight: 2 }}
                                                    variant="outlined"
                                                    size="small"
                                                    onClick={() => navigate(`/supervisors/${btoa(supervisor.id) }/details`)}
                                                >
                                                    View Details
                                                </Button>

                                                <Button
                                                    sx={{ marginRight: 2 }}
                                                    variant="outlined"
                                                    size="small"
                                                    onClick={() => navigate(`/supervisors/update/${btoa(supervisor.id) }`)}
                                                    disabled={(hasPermissions('editDr',permissions)||getRole=='ADMINISTRATOR' || getRole=='SUPER_USER')?false:true}  >
                                                    Update
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                ):(<NoDataCard title={"No Supervisors Data"}/>)
            }
            

        </Container>
    );
};
export default SupervisorListing;