import { Box, Button, Container, CssBaseline, Grid, Paper, TextField } from "@mui/material";
import TitleBanner from "../../TitleBanner/TitleBanner";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FieldValues, FormProvider, useForm, useFormState } from "react-hook-form";
import { object, string } from "yup";
import { useNavigate } from "react-router-dom";
import isEmpty from "lodash-es/isEmpty";
import { getLoggedInUser } from "../../../utils/util";
import { addPromoDTO } from "../../../utils/Types/DTO";
import { assignPromo } from "../../../API/services";

const AddPromo = () => {
    const navigate = useNavigate();
    const getLoggedInUserEmail = getLoggedInUser();
    const validationSchema = object().shape({
        userEmail: string().required('Email is required').email('Email is invalid'),
        userFirstName: string().required('First Name is required'),
        userLastName: string().required('Last Name is required'),
    });

    const formOptions = {
        resolver: yupResolver(validationSchema)
    }
    const methods = useForm(formOptions);

    const { handleSubmit, register } = methods;
    const formState = useFormState({
        control: methods.control,
    });

    const onFormSubmit = (data: FieldValues) => {

        if (!isEmpty(data.userFirstName) && !isEmpty(data.userLastName) && !isEmpty(data.userEmail) && getLoggedInUserEmail) {
            const payload: addPromoDTO = {
                "buyer": {
                    "email": getLoggedInUserEmail,
                    "firstname": "Super",
                    "lastname": "Admin"
                },
                "respondents": [
                    {
                        "email": data.userEmail,
                        "firstname": data.userFirstName,
                        "lastname": data.userLastName
                    }
                ],
                "lineItems": [
                    {
                        "assessmentId": "ASMT-21499"
                    }
                ]
            }

            submitData(payload);
        }


    };

    const submitData = (payload: addPromoDTO) => {


        assignPromo(payload).then(
            async (response) => {

                if (response.data) {
                    console.log(response.data);
                    //  navigate("/org");
                }
            })
            .catch(
                (error) => {
                    console.log(error)
                }
            )
    };

    return (
        <Container component="main">
            <CssBaseline />
            <TitleBanner title={"Add Promo Credits"} />
            <Paper elevation={3} style={{ padding: '20px', margin: '10px' }}>
                <FormProvider {...methods}>
                    <Box component="form" onSubmit={handleSubmit(onFormSubmit)} sx={{ mt: 1 }}>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="userFirstName"
                                    control={methods.control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            margin="normal"
                                            fullWidth
                                            id="userFirstName"
                                            type="text"
                                            label="Respondent First Name"
                                            name="userFirstName"
                                            autoComplete="userFirstName"
                                            required
                                            autoFocus
                                            error={!!formState.errors.userFirstName}
                                            helperText={formState.errors.userFirstName ? formState.errors.userFirstName.message : ''}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="userLastName"
                                    control={methods.control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            margin="normal"
                                            fullWidth
                                            id="userLastName"
                                            type="text"
                                            label="Respondent Last Name"
                                            name="userLastName"
                                            autoComplete="userLastName"
                                            required
                                            autoFocus
                                            error={!!formState.errors.userLastName}
                                            helperText={formState.errors.userLastName ? formState.errors.userLastName.message : ''}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="userEmail"
                                    control={methods.control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            margin="normal"
                                            fullWidth
                                            id="userEmail"
                                            type="email"
                                            label="Respondent Email"
                                            name="userEmail"
                                            autoComplete="userEmail"
                                            required
                                            autoFocus
                                            error={!!formState.errors.userEmail}
                                            helperText={formState.errors.userEmail ? formState.errors.userEmail.message : ''}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Add Promo License
                        </Button>
                    </Box>
                </FormProvider>

            </Paper>
        </Container>

    )
};
export default AddPromo;