import { Button, Card, CardContent, Container, CssBaseline, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import TitleBanner from "../TitleBanner/TitleBanner";
import { useEffect, useState } from "react";
import { useNotificationStore } from "../../store/NotificationStore";
import { failed, success, warning } from "../Notification/NotificationMessages";
import { addUserDefinedSkills, getUserDefinedSkills } from "../../API/services";
import { useUserContext } from "../../context/UserContext";
import { getErrorMessage, getRoleToken } from "../../utils/util";


interface Skill {
    id: number;
    name: string;
}
interface SkillData {
    skillName: string;
    value: number;
}
// Your styles for the cards
const cardStyles = {
    height: '100%', // Make sure all cards are of equal height
    display: 'flex', // Use flexbox to manage the layout
    flexDirection: 'column', // Stack children vertically
};

const cardContentStyles = {
    flexGrow: 1, // Allow card content to fill the space
    overflow: 'hidden', // Hide overflow
};

const typographyStyles = {
    textOverflow: 'ellipsis', // Add ellipsis for overflow
    overflow: 'hidden', // Hide overflow
    whiteSpace: 'nowrap', // No wrapping to a new line
    fontSize: '1rem', // Consistent font size
    lineHeight: '1.5rem', // Consistent line height
};

const skillsList: Skill[] = [
    { id: 1, name: 'Integrity Awareness' },
    { id: 2, name: 'Personal & Professional Accountability' },
    { id: 3, name: 'Effective Listening' },
    { id: 4, name: 'Communication' },
    { id: 5, name: 'Emotional Intelligence' },
    { id: 6, name: 'Critical Thinking' },
    { id: 7, name: 'Problem Solving & Decision Making' },
    { id: 8, name: 'Conflict Resolution' },
    { id: 9, name: 'Influence & Persuasion' },
    { id: 10, name: 'Self Confidence' },
    { id: 11, name: 'Stress Management' },
    { id: 12, name: 'DEFAULT' },
];
const UserDefinedSkills = () => {

    const loggedInUserDetails = useUserContext();
    const getRole=getRoleToken();
    const [selectedSkill, setSelectedSkill] = useState<string>('');
    const [pageRefresh, setPageRefresh] = useState<boolean>(false);
    const [skillsData, setSkillsData] = useState<SkillData[]>([]);
    const [score, setScore] = useState<number>(0);
    const setNotification = useNotificationStore((state) => state.setNotification);

    useEffect(() => {
        const fetchSkillsData = async () => {
            if (loggedInUserDetails) {

                // Get all keys (IDs) from the organizationMap
                const organizationIds = Object.keys(loggedInUserDetails.organizationMap);

                // Accessing the first ID inside organizationMap  
                const firstOrganizationId = organizationIds[0];
                const responseData = await getUserDefinedSkills(firstOrganizationId);

                setSkillsData(responseData.data);
            }

        };

        fetchSkillsData();
    }, [pageRefresh]);

    const handleSkillChange = (event: SelectChangeEvent<string>) => {
        setSelectedSkill(event.target.value as string);
    };

    const handleScoreChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setScore(Number(event.target.value));
    };

    const handleSubmit = async () => {
        // {
        //     "skillName": "effective listening",
        //     "value": 50.5,
        //     "group": null
        // }
        const organizationIds = Object.keys(loggedInUserDetails.organizationMap);
        if (!selectedSkill) {
            setNotification(warning('Please select a skill'));
            return;
        }

        const payload = {
            "skillName": selectedSkill,
            "value": score,
            "group": null
        };
        console.log(`Submitted ${selectedSkill} with score ${score}`);

        try {
            const res = await addUserDefinedSkills(organizationIds[0] ?? ''
                , payload);
            setNotification(success(`Submitted ${selectedSkill} with score ${score}`));
        } catch (e:any) {
            console.log(e);
            setNotification(failed(getErrorMessage(e.response.data)));
        }

        setPageRefresh(!pageRefresh);
    };


    return (
        <Container component="main">
            <CssBaseline />

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TitleBanner title={"Add User Defined Skill Scores"} />
                </Grid>
                {getRole=='ADMINISTRATOR' && (
                    
                <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel id="skill-select-label">Skill Name</InputLabel>
                    <Select
                        labelId="skill-select-label"
                        id="skill-select"
                        value={selectedSkill}
                        label="Skill Name"
                        onChange={handleSkillChange}
                    >
                        {skillsList.map((skill) => (
                            <MenuItem key={skill.id} value={skill.name}>{skill.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    label="Score"
                    type="number"
                    value={score}
                    onChange={handleScoreChange}
                    fullWidth
                    margin="normal"

                />
                <Button onClick={handleSubmit} variant="contained" color="primary">
                    Submit
                </Button>
            </Grid>

                )}

                <Grid container spacing={2} justifyContent="center" item xs={12}>
                    {skillsData.map((skill, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>

                            <Card
                                sx={{
                                    py: 5,
                                    boxShadow: 0,
                                    textAlign: 'center',
                                    bgcolor: '#D1E9FC',
                                    color: '#061B64',
                                    height: '100%', // Make sure all cards are of equal height
                                    display: 'flex', // Use flexbox to manage the layout
                                    flexDirection: 'column', // Stack children vertically
                                    mb: 2, // Margin bottom for spacing between cards
                                }}
                            >
                                <CardContent
                                    sx={{
                                        flexGrow: 1, // Allow card content to fill the space
                                        '&:last-child': { paddingBottom: '16px' }, // Override MUI padding if needed
                                    }}
                                >
                                    <Typography variant="h3">{skill.value}</Typography>
                                    <Typography fontWeight={600} variant="subtitle2" sx={{ mb: 1, whiteSpace: 'nowrap', overflow: 'clip', textOverflow: 'ellipsis', opacity: 0.72, width: '50%', ml: '25%' }}>
                                        {skill.skillName}
                                    </Typography>
                                </CardContent>

                            </Card>
                        </Grid>

                    ))}
                </Grid>

            </Grid>

        </Container>
    );
};
export default UserDefinedSkills;