import { omit } from 'lodash-es';
import { useNotificationStore } from '../../store/NotificationStore';

import Notification from './Notification';


const CustomNotification = () => {
    const notification = useNotificationStore((state) => state.notification);

    const notify = useNotificationStore((state) => state.notify);

    return (
        <Notification
            horizontal="right"
            autoHideDuration={notification.autoHideDuration ?? 1500}
            notify={notify}
            notificationObject={omit(notification, ['autoHideDuration'])}
        />
    );
};

export default CustomNotification;



