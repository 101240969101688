import { Avatar, Box, Button, Container, CssBaseline, Grid, Paper, Stack, Typography } from "@mui/material";
import BarChart from "../Graphs/BarCharts/BarChart";
import ScoreCard from "./components/ScoreCard";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { assessmentDataType } from "../../utils/Types/DTO";
import { downloadAssessmentReport, getAssessmentDetailsById, getCandidateScoreByEmail } from "../../API/services";
import DownloadIcon from '@mui/icons-material/Download';
import TitleBanner from "../TitleBanner/TitleBanner";
import HistoryIcon from '@mui/icons-material/History';
import { capitalizer, formatDate, getCookieByName, getErrorMessage, getLoggedInUser, getRoleToken } from "../../utils/util";
import PieChart from "../Graphs/PieCharts/PieChart";
import { useNotificationStore } from "../../store/NotificationStore";
import { failed } from "../Notification/NotificationMessages";


const Assessment = () => {
    const getLoggedInUserEmail = getLoggedInUser();
    const getRole = getRoleToken();
    const navigate = useNavigate();
    let { assessmentId } = useParams<{ assessmentId: string }>();
    assessmentId = atob(assessmentId ?? "");
    const setNotification = useNotificationStore((state) => state.setNotification);
    const [assessmentData, setAssessmentData] = useState<assessmentDataType>();

    useEffect(() => {
        const fetchAssessmentData = async () => {
            if (assessmentId) {
                const response = await getAssessmentDetailsById(assessmentId);
                if (response.data) setAssessmentData(response.data);
            }
            else if (getLoggedInUserEmail) {
                if (getRole == 'CANDIDATE') {
                    const res = await getCandidateScoreByEmail(getCookieByName('userId') ?? '');
                    setAssessmentData(res.data);
                }
                else navigate('/home');
            }

        };
        fetchAssessmentData();
    }, []);

    const downloadReport = async (id: string) => {
        try {
            const res = await downloadAssessmentReport(id);
            window.open(res.data, '_blank');
        } catch (e: any) {
            setNotification(failed(getErrorMessage(e.response.data)));
        }
    }


    const topics = [
        "Integrity Awareness",
        "Accountability",
        "Effective Learning",
        "Communication",
        "Emotional Intelligence",
        "Critical Thinking",
        "Problem Solving",
        "Conflict Resolution",
        "Influence & Persuasion",
        "Self-Confidence",
        "Stress Management",
    ];

    return (


        <Container component="main">
            <CssBaseline />
            {assessmentData && (
                <>
                    <TitleBanner title={"Assessment Details"} rightChild={
                        <Stack direction="row" spacing={4}>
                            <Stack style={{ cursor: "pointer" }} onClick={() => downloadReport(assessmentData.assessmentId)} alignItems={"center"} direction={"row"} spacing={1}>
                                <DownloadIcon style={{ color: 'white' }} />
                                <Typography align="center" color={"white"} variant="h1" fontSize={18} sx={{ opacity: 1 }}>Download Report</Typography>
                            </Stack>
                            <Stack style={{ cursor: "pointer" }} onClick={() => { navigate(`/assessment/history/${btoa(assessmentData.email)}`) }} alignItems={"center"} direction={"row"} spacing={1}>
                                <HistoryIcon style={{ color: 'white' }} />
                                <Typography align="center" color={"white"} variant="h1" fontSize={18} sx={{ opacity: 1 }}>View Assessment History</Typography>
                            </Stack>
                        </Stack>
                    } />


                    <Paper elevation={3} style={{ padding: '20px', margin: '10px' }}>

                        <Grid container spacing={2}>
                            <Grid item xs={3} md={6} borderRadius={1.5}>

                                <Stack spacing={2}>
                                    <Typography variant="body1">
                                        <strong>Full Name:</strong> {assessmentData.fullName}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Birth Year Range:</strong> {assessmentData.yearOfBirth}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Phone:</strong> {assessmentData.phone}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3} md={6} borderRadius={1.5}>

                                <Stack spacing={2}>
                                    <Typography variant="body1">
                                        <strong>Email:</strong> {assessmentData.email}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Address:</strong> {assessmentData.state},{assessmentData.country}
                                    </Typography>

                                    <Typography variant="body1">
                                        <strong>Date of Assessment:</strong> {formatDate(assessmentData.dateTime)}
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>



                    </Paper>

                    <Paper elevation={3} style={{ padding: '20px', margin: '10px' }}>

                        <>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <ScoreCard title={assessmentData.totalScore ? "Total Score" : "Average Score"} total={assessmentData.totalScore ? assessmentData.totalScore : assessmentData.averageScore} />
                                </Grid>
                            </Grid>
                            <Grid marginTop={2} container spacing={3}>
                                {
                                    assessmentData.skillList.map((skillListItem) => (
                                        <>
                                            <Grid item xs={12} sm={6} md={3}>
                                                <ScoreCard title={capitalizer(skillListItem.name)} total={skillListItem.score} minimum={skillListItem.required} result={skillListItem.result === 'PASS' ? true : false} />
                                            </Grid>
                                        </>
                                    ))
                                }
                            </Grid>

                        </>



                        {/* <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <ScoreCard title={"Average Score"} total={randomGenerator().score + 50} />
                    </Grid>
                </Grid> */}
                        {/* <Grid marginTop={2} container spacing={3}>
                    {
                        topics.map((topic) => (
                            <>
                                <Grid item xs={12} sm={6} md={3}>
                                    <ScoreCard title={topic} total={randomGenerator().score} result={randomGenerator().result} />
                                </Grid>
                            </>
                        ))
                    }
                </Grid> */}
                    </Paper>
                    <Paper elevation={3} style={{ padding: '20px', margin: '10px' }}>
                        <Grid m={2} direction={'row'} container spacing={3}>
                            <BarChart title={"Average Score"} scoreData={assessmentData.skillList} />

                            <PieChart title={"Average Score"} scoreData={assessmentData.skillList} />
                        </Grid>
                    </Paper>
                </>
            )}




        </Container>

    );
};
export default Assessment;