import React from 'react';
import { Card, CardContent, Typography, Button, Container, CssBaseline, Grid } from '@mui/material';
import { UserDetailsDTO } from '../../utils/Types/DTO';
import { StyleChip } from '../../HrDashboard/CandidateScores/CandidatesList';


interface ProfileProps {
    user: UserDetailsDTO;
    onEdit: () => void;
}

const ViewProfile: React.FC<ProfileProps> = ({ user, onEdit }) => (
        <Container component="main">
        <CssBaseline />
        {/* <Card>
            <CardContent>
                <Typography>{user.firstName} { user.lastName}</Typography>
                <Typography variant="h5">{user.fullName}</Typography>
                <Typography color="textSecondary">{user.email}</Typography>
                <Typography>{user.phone}</Typography>
                <Typography>{user.yearOfBirth}</Typography>
                <Typography>{user.country}, {user.state}, {user.province}</Typography>
                <Button variant="contained" color="primary" onClick={onEdit}>
                    Edit Profile
                </Button>
            </CardContent>
        </Card> */}
          <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">Profile</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="textSecondary">Full Name</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>{user.fullName}</Typography>
          </Grid>
          <Grid item xs={2}>
          
            <StyleChip label={user.role} bgColor={"#f7fcd1"} />
          </Grid>
          <Grid item xs={6}>
            <Typography color="textSecondary">Email</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{user.email}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="textSecondary">Phone</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{user.phone}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="textSecondary">Year of Birth</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{user.yearOfBirth}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="textSecondary">Location</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{user.country}, {user.state}, {user.province}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={onEdit}>
              Edit Profile
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
        </Container>


    );
export default ViewProfile;