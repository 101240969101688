import { Container, Grid, Paper, Typography } from "@mui/material";
import BarChart from "../components/Graphs/BarCharts/BarChart";
import CandidateScores from "./CandidateScores/CandidateScores";

const HRHome=()=>{
    return(
        <Container component={"main"}>
            <Paper elevation={3} style={{ padding: '20px', margin: '10px' }}>
           
            <CandidateScores/>
            </Paper>
           
            {/* <BarChart/> */}
        </Container>
    );

}
export default HRHome;