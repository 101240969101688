import { Container, Grid, Paper, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCandidateScoreByEmail } from "../API/services";
import BarChart from "../components/Graphs/BarCharts/BarChart";
import PieChart from "../components/Graphs/PieCharts/PieChart";
import { assessmentDataType } from "../utils/Types/DTO";
import { getCookieByName, getLoggedInUser, getRoleToken } from "../utils/util";

const UserHome = () => {
    const getLoggedInUserEmail = getLoggedInUser();
    const getRole = getRoleToken();
    const navigate = useNavigate();
    const [assessmentData, setAssessmentData] = useState<assessmentDataType>();
    useEffect(() => {
        const fetchAssessmentData = async () => {
            if (getLoggedInUserEmail) {
                const res = await getCandidateScoreByEmail(getCookieByName('userId') ?? '');
                setAssessmentData(res.data);
            }

        };
        fetchAssessmentData();
    }, []);
    return (

        <Container component={"main"}>
            <Paper elevation={3} style={{ padding: '20px', margin: '10px' }}>
                {
                    assessmentData && (
                        <Grid marginTop={2}>

                            <BarChart title={"Average Score"} scoreData={assessmentData.skillList} />

                            <PieChart title={"Average Score"} scoreData={assessmentData.skillList} />
                        </Grid>
                    )
                }

            </Paper>
        </Container>
    );
};
export default UserHome;