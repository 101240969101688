import { Button, Chip, Container, CssBaseline, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { getCandidates, getLoggedInUserDetails } from "../../API/services";
import { getCookieByName, getLoggedInUser } from "../../utils/util";
import { userListType } from "../../utils/Types/DTO";
import { useNavigate, useParams } from "react-router-dom";
import TitleBanner from "../../components/TitleBanner/TitleBanner";
import { useUserContext } from "../../context/UserContext";
import { isEmpty } from "lodash-es";

interface supervisorType {
    id: string;
    firstName: string;
    lastName: string;
    fullName: string;
    yearOfBirth: string;
    phone: string;
    email: string;
    country: string;
    state: string;
    province: string;
    role: string;
    permissions: string;
}
/**
 * Represents a user type.
 */
interface userType{
    id: string;
    firstName: string;
    lastName: string;
    fullName: string;
    yearOfBirth: string;
    phone: string;
    email: string;
    country: string;
    state: string;
    organizationMap: {
            [key: string]: string;
        };

    province:string;
    role: string;
    manager: string;
    permissions: string;
}

type props = {
    bgColor: string;
};
const StyleChip = styled(Chip)(({ bgColor }: props) => ({
    backgroundColor: bgColor,
    borderRadius: 3,
}));
const SupervisorDetails = () => {
    // const loggedInUser = useUserContext();
    let { supervisorID } = useParams<{ supervisorID: string }>();
    supervisorID = atob(supervisorID ?? "");

    const navigate = useNavigate();

    const [supervisor, setSupervisor] = useState<userType>();
    const [userList, setUserList] = useState<userListType[]>();
    
    useEffect(() => {
        const getSupervisorsList = async () => {
            if (supervisorID && !isEmpty(supervisorID)) {
              const  supervisorDetailsResponse = await getLoggedInUserDetails(supervisorID);
                setSupervisor(supervisorDetailsResponse.data);

                const candidatesResponse = await getCandidates(supervisorID ?? '');
                if (candidatesResponse.data.list) {
                    setUserList(candidatesResponse.data.list);
                }
            }
        };
        getSupervisorsList();
    }, []);
    return (
        <Container component="main">
            <CssBaseline />
            <TitleBanner title={"Supervisors Details"} />
            {
                supervisor && (
                    <>
                        <Paper elevation={3} style={{ padding: '20px', marginBottom: '10px' }}>

                            <Grid container spacing={2}>
                                <Grid item xs={3} md={6} borderRadius={1.5}>

                                    <Stack spacing={2}>
                                        <Typography variant="body1">
                                            <strong>Full Name:</strong> {supervisor?.fullName}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Year of Birth:</strong> {supervisor.yearOfBirth}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Phone:</strong> {supervisor.phone}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={3} md={6} borderRadius={1.5}>

                                    <Stack spacing={2}>
                                        <Typography variant="body1">
                                            <strong>Email:</strong> {supervisor.email}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Address:</strong> {supervisor.state},{supervisor.country}
                                        </Typography>

                                        <Typography variant="body1">
                                            <strong>Role:</strong> {supervisor.role}
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Paper>
                        <Typography align="center" variant="body2" fontSize={25} sx={{ opacity: 0.8 }}>Assigned Applicants List</Typography>
                        {
                            userList && userList.length == 0 ? (
                                <Paper elevation={3} style={{ padding: '10px', marginTop: '10px' }}>
                                    <Typography align="center" variant="h6" fontSize={25} sx={{ opacity: 0.8 }}>No Applicants Found</Typography>
                                </Paper>
                            ):(
                                <TableContainer elevation={3} style={{ padding: '10px' }} component={Paper}>
                            <Table stickyHeader>
                                <TableHead>

                                    <TableRow>
                                        <TableCell >Name</TableCell>
                                        <TableCell >Email</TableCell>
                                        <TableCell >Average Score</TableCell>
                                        <TableCell align="center">Actions</TableCell>
                                    </TableRow>

                                </TableHead>
                                <TableBody>
                                    {
                                        userList && (
                                            userList.map((user) => (
                                                <TableRow key={user.id}>
                                                    <TableCell>{user.firstName} {user.lastName}</TableCell>
                                                    <TableCell>{user.email}</TableCell>
                                                    <TableCell><StyleChip label={user.totalScore} bgColor={"#f7fcd1"} /></TableCell>
                                                    <TableCell align="right">
                                                        <Button
                                                            onClick={() => navigate(`/assessment/${btoa(user.assessmentId)}/details`)}
                                                            sx={{ marginRight: 2 }}
                                                            variant="outlined"
                                                            size="small"
                                                        >
                                                            View Assessment
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                            )
                        }
                        
                    </>

                )
            }

        </Container>
    );
};
export default SupervisorDetails;