import { useEffect, useState } from "react";
import { AdminListingDTO, organizationDTO } from "../../utils/Types/DTO";
import {  getAdministratorsByOrgID, getOrganizationdetailsById } from "../../API/services";
import { getCookieByName, getRoleToken, hasPermissions } from "../../utils/util";
import { useNavigate, useParams } from "react-router-dom";
import { Container, CssBaseline, Stack, Typography, Paper, Grid, Box, FormControl, InputLabel, Select, MenuItem, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button } from "@mui/material";
import { Tooltip } from "chart.js";
import { filter, isEmpty } from "lodash";
import { StyleChip } from "../../HrDashboard/CandidateScores/CandidatesList";
import NoDataCard from "../../components/NoDataCard/NoDataCard";
import TitleBanner from "../../components/TitleBanner/TitleBanner";
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const AdminListingByOrg = () => {
    let { organizationId } = useParams<{ organizationId: string }>();
    organizationId = atob(organizationId ?? "");
    console.log(organizationId);
    const getRole=getRoleToken();
    const navigate = useNavigate();
    const [organizationDetails, setOrganizationDetails] = useState<organizationDTO>();
    const [listings, setListings] = useState<AdminListingDTO[]>([]);

    useEffect(() => {
        const fetchAdminListings = async () => {
            if(organizationId)
                {
                    try {
                        const organizationDetailsResponse = await getOrganizationdetailsById(organizationId);
                        if(organizationDetailsResponse.data) setOrganizationDetails(organizationDetailsResponse.data);
                        const response = await getAdministratorsByOrgID(organizationId);
                        if (response.data) setListings(response.data);
                    } catch (error) {
                        console.error('Error fetching admin listings:', error);
                    }
                }
        
        };
        // Fetch the admin listings from the server
        fetchAdminListings();
    }, []);
    return (   
        <Container component="main">
        <CssBaseline />
        {/* <TableComponent typeOfUser="supervisor" />
         */}
        
        <TitleBanner title={"Administrators Listing"} rightChild={
            getRole=='ADMINISTRATOR'&&(
            <Stack style={{ cursor: "pointer" }} onClick={() => { navigate(`/add/admin`) }} alignItems={"center"} direction={"row"} spacing={1}>
                <PersonAddIcon style={{ color: 'white' }} />
                <Typography align="center" color={"white"} variant="h1" fontSize={18} sx={{ opacity: 1 }}>Add Admin</Typography>

            </Stack>)} />

           
            {
            organizationDetails&&(
            <Paper elevation={3} style={{ padding: '20px', marginTop: '10px' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}  borderRadius={1.5}>
                                    <Stack spacing={2}>
                                        <Typography variant="h5">
                                            <strong>Organization Name:</strong> {organizationDetails.organizationName}
                                        </Typography>
                                        {/* <Typography variant="body1">
                                            <strong>Birth Year Range:</strong> {assessmentData.yearOfBirth}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Phone:</strong> {assessmentData.phone}
                                        </Typography> */}
                                    </Stack>
                                </Grid>
                            </Grid>
                </Paper>
            )
        }
  
        {
            !isEmpty(listings)?(
                    <TableContainer style={{ marginTop: '10px' }} component={Paper}>
                <Table stickyHeader>
                    <TableHead><TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        {/* <TableCell>Assigned Users</TableCell> */}
                        <TableCell>Designation</TableCell>
                        {/* <TableCell align="right">Actions</TableCell> */}
                    </TableRow></TableHead>
                    <TableBody>
                        {
                            listings && (
                                listings.map((admin) => (
                                    <TableRow key={admin.id}>
                                        <TableCell>{admin.firstName} {admin.lastName}</TableCell>
                                        <TableCell>{admin.email}</TableCell>
                                       
                                        <TableCell><StyleChip label={admin.tag} bgColor={"#f7fcd1"} /></TableCell>
                                        {/* <TableCell align="right">
                                        <Tooltip title="Assign Applicants">
                                            <Button
                                                sx={{ marginRight: 2 }}
                                                variant="contained"
                                                size="small"
                                                onClick={() => navigate(`/assessment/assign`)}
                                                disabled={(hasPermissions('addDr',permissions)||getRole=='ADMINISTRATOR')?false:true}
                                            >
                                                Assign Applicants
                                            </Button>
                                            </Tooltip>
                                            <Button
                                                sx={{ marginRight: 2 }}
                                                variant="outlined"
                                                size="small"
                                                onClick={() => navigate(`/supervisors/${btoa(supervisor.id) }/details`)}
                                            >
                                                View Details
                                            </Button>

                                            <Button
                                                sx={{ marginRight: 2 }}
                                                variant="outlined"
                                                size="small"
                                                onClick={() => navigate(`/supervisors/update/${btoa(supervisor.id) }`)}
                                                disabled={(hasPermissions('editDr',permissions)||getRole=='ADMINISTRATOR' || getRole=='SUPER_USER')?false:true}  >
                                                Update
                                            </Button>
                                        </TableCell> */}
                                    </TableRow>
                                ))
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            ):(<NoDataCard title={"No Admin Data"}/>)
        }
        

    </Container>
    );
};
export default AdminListingByOrg;