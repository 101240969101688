import { useEffect, useState } from "react";
import CoursesList from "./CoursesList";
import { Course } from "../../utils/Types/DTO";
import { Box, Button, Container, CssBaseline, IconButton, Typography } from "@mui/material";
import { useNotificationStore } from "../../store/NotificationStore";
import { getCookieByName, getLoggedInUser, openInNewTab } from "../../utils/util";
import { getCandidateCourseProgress } from "../../API/services";
import TitleBanner from "../TitleBanner/TitleBanner";
import logo from '../../assets/teachable_logo.png';

const Teachables = () => {
    const getLoggedInUserEmail = getLoggedInUser();
    const setNotification = useNotificationStore((state) => state.setNotification);
    const [courses, setCourses] = useState<Course[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getCoursesDetails = async () => {

            try {
                if (getLoggedInUserEmail) {
                    const response = await getCandidateCourseProgress(getCookieByName('userId') ?? '');
                    if (response.data) {
                        setCourses(response.data);
                    }
                }
                setLoading(true);
            } catch (e) {
                setLoading(true);
            }
        };
        getCoursesDetails();
    }, []);
    const teachables = () => openInNewTab('https://sso.teachable.com/secure/teachable_accounts/sign_in');
    return (
        <Container component="main">
            <CssBaseline />
            <TitleBanner title={"Teachable Courses Progress"} rightChild={
                <IconButton sx={{ border: 'ActiveBorder' }} onClick={() => teachables()}>
                    <img src={logo} alt="Logo" style={{ marginRight: '16px', height: '40px', width: '100px' }} />
                </IconButton>
            } />
            {loading && (<> {courses.length > 0 ? <CoursesList courses={courses} />
                : (<Box textAlign="center" mt={20}><Typography variant="h6">No courses found</Typography></Box>)}</>)}
        </Container>
    );
};
export default Teachables;