import { Box, Avatar, Typography, Grid, TextField, FormControlLabel, Checkbox, Button, Container, TextareaAutosize, Dialog, DialogContent } from "@mui/material";
import Link from '@mui/material/Link';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Controller, FieldValues, FormProvider, useForm, useFormState } from "react-hook-form";
import { userRegistration, validateAccessKey } from "../../API/services";
import { yupResolver } from "@hookform/resolvers/yup";
import { boolean, object, string } from "yup";
import AccessKeyDialog from "./AccessKeyDialog";
import isEmpty from "lodash-es/isEmpty";
import GlobalLoader from "../../API/useAxiosLoader";
import { registerPersonDTO } from "../../utils/Types/DTO";
import logo from '../../assets/noshadow.png';
import { useNotificationStore } from "../../store/NotificationStore";
import { failed, success } from "../Notification/NotificationMessages";
import { getErrorMessage } from "../../utils/util";

const Register = () => {
  const setNotification = useNotificationStore((state) => state.setNotification);
  const navigate = useNavigate();
  const { accessCode } = useParams<{ accessCode: string }>();

  const [showAccessKeyModal, setShowAccessKeyModal] = useState(!accessCode);
  const [accessKey, setAccessKey] = useState(accessCode);
  const [validationResponse, setValidationResponse] = useState<any>({});
  const [showRegistrationComplete, setShowRegistrationComplete] = useState(false);
  const [hrPermission, setHrPermission] = useState(false);
  const validationSchema = object().shape({
    password: string().min(6, 'Password must be at least 4 characters').required('Password is required'),
    // address: string().required('Address is required'),
    state: string().required('Address is required'),
    province: string().required('Address is required'),
    hrPermission:boolean().optional(),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema)
  }
  const methods = useForm(formOptions);

  const { handleSubmit, register } = methods;
  const formState = useFormState({
    control: methods.control,
  });

  useEffect(() => {
    const validation = async () => {

      if (accessCode) {
        try {
          const response = await validateAccessKey({ "accessKey": accessCode });

          if (response.data) {
            setValidationResponse(response.data);
            if (response.data.registrationCompleted) setShowRegistrationComplete(true);
          }

        } catch (error) {

        }
      }
    };

    validation();

  }, [accessCode]);

  const onFormSubmit = (data: FieldValues) => { 

    if (!isEmpty(data.password) && !isEmpty(data.province) && accessKey)
    {
      const payload: registerPersonDTO = {
        "country": data.province,
        "firstName": validationResponse.user && validationResponse.user.firstName,
        "lastName": validationResponse.user && validationResponse.user.lastName,
        "email": validationResponse.user && validationResponse.user.email,
        "group": "GRP",
        "fullName": `${validationResponse.user && validationResponse.user.firstName} ${validationResponse.user && validationResponse.user.lastName}`,
        "phone": "",
        "province":  data.province??'',
        "state":  data.state??"",
        "yearOfBirth": "",
        "hiringManager": hrPermission,
        "password": data.password,
        "accessKey": accessKey,
      }
      onSubmit(payload)
      }

  };
  const onSubmit = (payload: registerPersonDTO) => {
    userRegistration(payload).then(
      async (response) => {
      
        if (response.data) {
          setNotification(success('Registered Successfully'));
          navigate('/login');
        }
      })
      .catch(
        (error:any) => {
          console.log(error)
          setNotification(failed(getErrorMessage(error.response.data)));
        }
      )
   
  };

  const onAccessKeySubmit = async (accessCode:string) => { 
    setAccessKey(accessCode);
    try {
      const response = await validateAccessKey({ "accessKey": accessCode });

      if (response.data) {
        setValidationResponse(response.data);
        setShowAccessKeyModal(false);
        if (response.data.registrationCompleted) setShowRegistrationComplete(true);
      }

    } catch (error:any) {
      console.log(error.response.data.message)
      setNotification(failed(getErrorMessage(error.response.data)));
    }
  };


  return (
    <>
     {/* <GlobalLoader /> */}
    <Container component="main" maxWidth="xs">
      <AccessKeyDialog showAccessKeyModal={showAccessKeyModal} onClose={() => {
        setShowAccessKeyModal(false);
        navigate('/login');
      }} onSubmit={(accessCode) => onAccessKeySubmit(accessCode)} />

      <Dialog open={showRegistrationComplete} onClose={() => {
        setShowRegistrationComplete(false);
        navigate('/login');
      }}>
        <DialogContent>
          <Typography variant="h6">This Entity has been registered already! Login to continue</Typography>
         
          <Button
            variant="contained"
            color="primary"
            onClick={()=>{navigate('/login')}}
          >
            Login
          </Button>
        </DialogContent>
      </Dialog>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
         <img src={logo} alt="Logo" style={{ marginRight: '16px', height: '40px', width: '200px' }} />
        {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar> */}
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <FormProvider {...methods}>
        <Box component="form" onSubmit={handleSubmit(onFormSubmit)}  sx={{ mt: 3 }}>
          <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={true}
                  fullWidth
                  name="firstName"
                 
                  type="text"
                  id="firstName"
                  value={validationResponse.user && validationResponse.user.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={true}
                  fullWidth
                  name="lastName"
                  type="text"
                  id="lastName"
                  value={validationResponse.user && validationResponse.user.lastName}
                />
              </Grid>
            <Grid item xs={12}>
                <Controller
                  name="password"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      margin="normal"
                      fullWidth
                      id="password"
                      type="password"
                      label="Password"
                      name="password"
                      autoComplete="password"
                      required
                      autoFocus
                      error={!!formState.errors.password}
                      helperText={formState.errors.password ? formState.errors.password.message : ''}
                      
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="state"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      margin="normal"
                      fullWidth
                      id="state"
                      type="text"
                      label="City / State"
                      name="state"
                      autoComplete="state"
                      required
                      autoFocus
                      error={!!formState.errors.state}
                      helperText={formState.errors.state ? formState.errors.state.message : ''}
                      
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="province"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      margin="normal"
                      fullWidth
                      id="province"
                      type="text"
                      label="Province / Country"
                      name="province"
                      autoComplete="province"
                      required
                      autoFocus
                      error={!!formState.errors.state}
                      helperText={formState.errors.state ? formState.errors.state.message : ''}
                      
                    />
                  )}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <Typography>Address*</Typography>
                <Controller
                  name="address"
                  control={methods.control}
                  render={({ field }) => (
                    <textarea
                      {...field}
                      id="address"
                      name="address"
                      autoComplete="address"
                      required
                      style={{ width: '400px', height: '50px' }}
                    />
                  )}
                />
              </Grid> */}
            
              <Grid item xs={12}>
                <TextField
                  disabled={true}
                  fullWidth
                  name="email"
                 
                  type="email"
                  id="email"
                  value={validationResponse.user && validationResponse.user.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                 disabled={true}
                  fullWidth
                  name="organization"
                  type="text"
                  id="organization"
                  value={validationResponse && validationResponse.organizationName}
                />
              </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...register('hrPermission')}
                      checked={hrPermission}
                      onChange={(e) => setHrPermission(e.target.checked)}
                    />
                  }
                  label="Do you want Hiring Manager permission?"
                />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
              sx={{ mt: 3, mb: 2 }}
          >
            Register
          </Button>
        </Box>
        </FormProvider>
       
            
        
          <Grid container justifyContent="flex-end">
            <Grid item>
            <Link sx={{ cursor: 'pointer' }} onClick={() => {
              navigate("/login");
            }} variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
         
        
      </Box>
      </Container>
    </>
  );
};
export default Register;