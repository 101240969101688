import { ArrowBack } from "@mui/icons-material";
import { Box, Container, CssBaseline, Grid, ListItem, Paper, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";
import { useNavigate } from 'react-router-dom';
interface TitleBannerProps{
    title: string;
    rightChild?: ReactNode;
    arrow?: boolean;
}
const TitleBanner = ({ title, rightChild,arrow=true }: TitleBannerProps) => { 
    const navigate = useNavigate(); 
    return (
        <Box  >
        {/* <Container  component="header"> */}
            <CssBaseline />
            <Paper elevation={3} style={{ padding: '20px', backgroundColor: '#0088f2' }}>
                <Stack direction={"row"}alignItems={"center"} justifyContent={"space-between"} >
                <Stack  direction={"row"} spacing={4}  alignItems={"center"} >
                    {arrow && ( <ArrowBack style={{ color:'white' }} cursor="pointer" onClick={() => navigate(-1) } />)}
                   
                {/* <Grid item> */}
                    <Typography align="center"color={"white"} variant="h1" fontSize={18} sx={{ opacity: 1 }}>{title}</Typography>
                    {/* </Grid> */}
                    
                    </Stack>
                    {
                        rightChild && (
                            rightChild

                        )
                    }
                    
                </Stack>
            </Paper>

       {/* </Container> */}
       </Box >
    );
};
export default TitleBanner;