import { Container, FormControl, InputLabel, Select, MenuItem, Button, SelectChangeEvent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllAssessmentTypes } from '../../../API/services';
import { AvailableProductsDTO, Product } from '../licenseManagement/AddAssessment';
import TitleBanner from '../../TitleBanner/TitleBanner';

const assessments = [
    // { id: "ASMT-22288", name: "ESAT™ Grow Promo" },
    { id: "ASMT-20827", name: "ESAT™ Grow" },
    { id: "ASMT-20824", name: "ESAT™ Screen" },
    // { id: "ASMT-21499", name: "ESAT™ Screen Promo" }
    // ... add other assessments here
];

const AssessmentSelectionComponent: React.FC = () => {
    const [products, setProducts] = useState<Product[]>([]);
    const [selectedAssessment, setSelectedAssessment] = useState<string | null>(null);
    const [availableProducts, setAvailableProducts] = useState<AvailableProductsDTO[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAssessmentTypes = async () => {


            const response = await getAllAssessmentTypes();
            if (response.data) {
                const fetchedProducts: Product[] = response.data;

                const filteredProducts = fetchedProducts.filter(
                    product => product.assessmentCode !== "ASMT-21499" && product.assessmentCode !== "ASMT-22288"
                );

                setProducts(filteredProducts);
            }


        };
        fetchAssessmentTypes();
    }, []);



    const handleChange = (event: SelectChangeEvent<string | null>) => {
        setSelectedAssessment(event.target.value as string);
        console.log(event.target.value as string);
    };

    const handleProceed = () => {
        if (selectedAssessment) {
            navigate('/assessment/assign/respondents', { state: { assessmentId: selectedAssessment } });
        }
    };

    return (
        <Container>
            <TitleBanner title={"Select Assessment Type"} />
            <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel id="assessment-select-label">Select Assessment</InputLabel>
                <Select
                    labelId="assessment-select-label"
                    value={selectedAssessment}
                    onChange={handleChange}
                    label="Select Assessment"
                >
                    {products.map(assessment => (
                        <MenuItem key={assessment.id} value={assessment.assessmentCode}>
                            {assessment.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Button variant="contained" color="primary" onClick={handleProceed}>
                Proceed
            </Button>
        </Container>
    );
}

export default AssessmentSelectionComponent;
