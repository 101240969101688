// CourseProgress.tsx
import React from 'react';
import { Card, CardContent, Typography, LinearProgress } from '@mui/material';
import { Course } from '../../utils/Types/DTO';



interface CourseProgressProps {
  course: Course;
}

const CourseProgress: React.FC<CourseProgressProps> = ({ course }) => {
  return (
    <Card variant="outlined" sx={{ marginBottom: 2 }}>
      <CardContent>
        <Typography variant="h6">{course.course_name}</Typography>
        <LinearProgress variant="determinate" value={course.percent_complete} />
        <Typography color="textSecondary">
          {course.percent_complete}% Complete
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CourseProgress;
