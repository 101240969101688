import { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import { getCookieByName, getLoggedInUser } from "../utils/util";
import { getLoggedInUserDetails } from "../API/services";
import { isEmpty } from "lodash";

interface IUser {
    id?: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    yearOfBirth?: string;
    phone?: string;
    email?: string;
    country?: string;
    state?: string;
    province?: string;
    role?: string;
    manager?: string;
    permissions?: string;
    organizationMap?: any;
    setUser?: Dispatch<SetStateAction<IUser | null>>;
}

export const UserContext = createContext<IUser | null>(null);

export default function UserContextProvider(props: PropsWithChildren<IUser | null>){

    const [user, setUser] = useState<IUser | null>(null);
    const userEmail = getLoggedInUser();

    useEffect(() => {
        (async () => {
            if(!isEmpty(userEmail) && userEmail && isEmpty(user)){
                const res = await getLoggedInUserDetails(getCookieByName('userId') ?? '');
                setUser(res.data);
            }
        })();
    }, []);

    return <UserContext.Provider value={{...user, setUser}}>{props.children}</UserContext.Provider>
}

export function useUserContext() {
    const context = useContext(UserContext);
    if(!context){
        throw new Error('state must be used within the context provider');
    }
    return context;
}