import React, { useState } from "react"
import { parse } from "date-fns"
import { TextField, Box, Stack, Typography } from "@mui/material"

const DateRangePicker: React.FC<any> = ({
  endDate,
  startDate,
  setEndDate,
  setStartDate,
}) => {
  const [error, setError] = useState<string | null>(null)

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const date = event?.target?.value
    setStartDate(date)
    const parsedDate = parse(date, "yyyy-MM-dd", new Date())
    const parsedEndDate = parse(endDate, "yyyy-MM-dd", new Date())
    if (parsedEndDate && parsedDate > parsedEndDate) {
      setError("Start date cannot be after the end date")
    } else {
      setError(null)
    }
  }

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const date = event?.target?.value
    setEndDate(date)
    const parsedDate = parse(date, "yyyy-MM-dd", new Date())
    const parsedStartDate = parse(startDate, "yyyy-MM-dd", new Date())
    if (parsedStartDate && parsedDate < parsedStartDate) {
      setError("End date cannot be before the start date")
    } else {
      setError(null)
    }
  }

  return (
    <Stack sx={{ gap: 2 }}>
      <Stack sx={{ flexDirection: "row", gap: 2 }}>
        <TextField
          type="date"
          value={startDate}
          label="Date Filter From:"
          onChange={handleStartDateChange}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          type="date"
          value={endDate}
          label="Date Filter To:"
          onChange={handleEndDateChange}
          InputLabelProps={{ shrink: true }}
        />
      </Stack>
      {error && (
        <Box color="error.main">
          <Typography variant="caption">{error}</Typography>
        </Box>
      )}
    </Stack>
  )
}

export default DateRangePicker
