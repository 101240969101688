import { Stack } from "@mui/material"
import { useEffect, useState } from "react"
import { useSuperAdminStore } from "../../store/SuperAdminStore"
import OptionDropDown from "../OptionsList/optionsDropDown"
import CandidateScores from "../../HrDashboard/CandidateScores/CandidateScores"
import { SimpleCardWithTitleAndValue } from "./components/SimpleCardWithTitleAndValue"
import { skillsOutlineDTO } from "../../utils/Types/DTO"
import { screeningOutline } from "../../API/services"

export const AssessmentDashboard = () => {
  const cardData = useSuperAdminStore((state) => state?.cardInfo)
  const orgList = useSuperAdminStore((state) => state?.orgList)

  const { title, score, bgColor, isSoftSkillsCard } = cardData || {}
  const [selectedOption, setSelectedOption] = useState<string>("")
  const [skillOutline, setSkillOutline] = useState<skillsOutlineDTO[]>()

  const selectedOrgID: any = orgList?.find(
    (item: any) => item?.organizationName == selectedOption
  )?.id

  const handleSelectOrg = (event: any) => {
    setSelectedOption(event?.target?.value)
  }

  const getOrgsList = async () => {
    const response = await screeningOutline(selectedOrgID ?? "")
    if (response.data) {
      setSkillOutline(response.data)
    }
  }

  useEffect(() => {
    const getOrgsList = async () => {
      const response = await screeningOutline("")
      if (response.data) {
        setSkillOutline(response.data)
      }
    }
    getOrgsList()
  }, [])

  return (
    <Stack
      sx={{ alignItems: "center", gap: 4, maxWidth: "80%", margin: "0 auto" }}>
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: 3 }}>
        <Stack gap={2}>
          <OptionDropDown
            selectedOption={selectedOption}
            handleOnChange={handleSelectOrg}
            setSelectedOption={setSelectedOption}
            onSearchClickCB={getOrgsList}
          />
        </Stack>
        <SimpleCardWithTitleAndValue
          title={title}
          value={score}
          bgColor={bgColor}
          isSoftSkillCard={isSoftSkillsCard}
          url={"#"}
        />
      </Stack>
      <CandidateScores props={skillOutline} />
    </Stack>
  )
}
