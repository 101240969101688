import create from 'zustand';

export interface INotification {
    message: string;
    type: 'error' | 'info' | 'success' | 'warning';
    autoHideDuration?: number;
}

export interface NotificationState {
    notification: INotification;
    notify: boolean;
    setNotification: (notification: INotification) => void;
    closeNotification: () => void;
}

export const useNotificationStore = create<NotificationState>((set) => ({
    notification: {
        message: '',
        type: 'info',
        autoHideDuration: 1500,
    },
    notify: false,
    setNotification: (notifyMsg: INotification) => set((state) => ({ ...state, notification: notifyMsg, notify: true })),
    closeNotification: () => set((state) => ({ ...state, notify: false })),
}));
