import { isEmpty } from 'lodash-es';
import { useCallback, useEffect } from 'react';
import { getAuthToken, setCookieByName } from './util';
import { refreshToken } from '../API/services';


export const PollingHandler = () => {
    const authToken = getAuthToken();

    console.log("initPolling");

    const KEY_LAST_EXECUTION_TIME = 'lastExecutionTime';
    const EXECUTION_INTERVAL_MS = 4 * 60 * 1000;

    const pollingFunction = useCallback(() => {
        const pollingForNewData = async () => {
            if (!isEmpty(authToken)) {

                console.log('hit refreshToken');
                const response = await refreshToken();
                if (response.data)
                {
                    setCookieByName('Authorization', response.data.authorization, 0);
                    setCookieByName('SessionID', response.data.sessionID, 0);
                    }
            }
        };
        pollingForNewData();
    }, [authToken]);

    const canExecute = useCallback(() => {
        const lastExecutionTime = localStorage.getItem(KEY_LAST_EXECUTION_TIME);
        const now = new Date().getTime();
        if (!lastExecutionTime || now - Number(lastExecutionTime) >= EXECUTION_INTERVAL_MS) {
            localStorage.setItem(KEY_LAST_EXECUTION_TIME, now.toString());
            return true;
        }
        return false;
    }, [EXECUTION_INTERVAL_MS]);

    const startExecution = useCallback(() => {
        if (canExecute()) {
            pollingFunction();
        }
    }, [canExecute, pollingFunction]);

    useEffect(() => {
        if (!isEmpty(authToken)) {
            console.log('initiated');
            const intervalId = setInterval(startExecution, EXECUTION_INTERVAL_MS);
            return () => clearInterval(intervalId);
        }
    }, [EXECUTION_INTERVAL_MS, authToken, startExecution]);

    return <></>;
};


