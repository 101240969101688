import { create } from "zustand"
import { persist, createJSONStorage } from "zustand/middleware"

interface CardInfo {
  title: string
  score: number
  bgColor: string
  isSoftSkillsCard: boolean
}

export interface SuperAdminStoreState {
  orgList:any[]
  cardInfo: CardInfo
  setOrgList: (list: any) => void
  setCardInfo: (info: CardInfo) => void
}

export const useSuperAdminStore = create(
  persist<SuperAdminStoreState>(
    (set) => ({
      orgList:[],
      cardInfo: {
        title: "",
        score: 0,
        bgColor: "",
        isSoftSkillsCard: false,
      },
      setCardInfo: (info: CardInfo) => set({ cardInfo: info }),
      setOrgList: (list: any) => set({ orgList: list }),
    }),
    {
      name: "orgList",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)
