import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { styled } from '@mui/material/styles';
import React from 'react';

import { IUser } from '../../API/services';

const Link = styled('div')(() => ({
    color: "blue",
    cursor: 'pointer',
    display: 'inline',
}));
interface ILoginAlert {
    loginParam: IUser;
    statusCode: number;
    overRideToken(args: IUser): void;
}

const LoginAlert: React.FC<ILoginAlert> = ({
    loginParam,
    statusCode,
    overRideToken,
}) => {
    let errorElement = (
        <Alert severity="error">Failed to sign in! Please check your credentials and try again.</Alert>
    );
    switch (statusCode) {
        case 409:
            errorElement = (
                <Alert severity="warning">
                    <AlertTitle>Another user is already Signed in!</AlertTitle>
                    <Link onClick={() => overRideToken(loginParam)}>Click</Link>
                    &nbsp;to log the other user out.
                </Alert>
            );
            break;
        case 404:
            errorElement = <Alert severity="error">Email not registered yet.</Alert>;
            break;
        case 423:
            errorElement = (
                <Alert severity="error">
                    <AlertTitle>Failed to sign in!</AlertTitle>
                    You signed up but your Account is deactivated.
                </Alert>
            );
            break;
        case 412:
            if (process.env.REACT_APP_URL) {
                window.location.reload();
            }
            break;
        case 500:
            errorElement = (
                <Alert severity="error">
                    <AlertTitle>Failed to sign in!</AlertTitle>
                    Unknown error from server. Please try after some time.
                </Alert>
            );
            break;
    }
    return errorElement;
};
export default LoginAlert;



