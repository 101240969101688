import { Box, Grid, TextField, Typography, FormControlLabel, Checkbox, Button, ButtonGroup } from "@mui/material";
import { Container } from "@mui/system";
import { useState } from "react";
import { Link } from "react-router-dom";

const AddUser = () => {
  const [selectedTopicId, setSelectedTopicId] = useState<number>(-1);
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };
  return (
    <Container component="main" maxWidth="xs">
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ButtonGroup
              sx={{
                margin: 2,
              }}
              size="large"
              aria-label="large button group"
            >
              <Button

                variant={
                  selectedTopicId === 1
                    ? "contained"
                    : "outlined"
                }
                onClick={() => setSelectedTopicId(1)}
              >
                Supervisor
              </Button>
              <Button

                variant={
                  selectedTopicId === 2
                    ? "contained"
                    : "outlined"
                }
                onClick={() => setSelectedTopicId(2)}
              >
                Admin
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="given-name"
              name="firstName"
              required
              fullWidth
              id="firstName"
              label="First Name"
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="family-name"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Add
        </Button>
      </Box>
    </Container>
  );
};
export default AddUser;