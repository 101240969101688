// EditProfile.tsx
import React, { useState } from 'react';
import { Card, CardContent, TextField, Button, Container, CssBaseline } from '@mui/material';
import { UserDetailsDTO } from '../../utils/Types/DTO';



interface EditProfileProps {
  user: UserDetailsDTO;
  onSave: (user: UserDetailsDTO) => void;
}

const EditProfile: React.FC<EditProfileProps> = ({ user, onSave }) => {
  const [editedUser, setEditedUser] = useState<UserDetailsDTO>({ ...user });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedUser({ ...editedUser, [event.target.name]: event.target.value });
  };

  const handleSave = () => {
    onSave(editedUser);
  };

  return (
    <Container component="main">
    <CssBaseline />
    <Card>
      <CardContent>
        <TextField
          label="First Name"
          name="firstName"
          value={editedUser.firstName}
          onChange={handleChange}
          margin="normal"
          fullWidth
        />
        <TextField
          label="Last Name"
          name="lastName"
          value={editedUser.lastName}
          onChange={handleChange}
          margin="normal"
          fullWidth
        />
        {/* Email is displayed but not editable */}
        <TextField
          label="Email"
          value={editedUser.email}
          margin="normal"
          fullWidth
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          label="Phone"
          name="phone"
          value={editedUser.phone}
          onChange={handleChange}
          margin="normal"
          fullWidth
        />
         <TextField
          label="Country"
          name="country"
          value={editedUser.country}
          onChange={handleChange}
          margin="normal"
          fullWidth
        />
        <TextField
          label="State"
          name="state"
          value={editedUser.state}
          onChange={handleChange}
          margin="normal"
          fullWidth
        />
        <TextField
          label="Province"
          name="province"
          value={editedUser.province}
          onChange={handleChange}
          margin="normal"
          fullWidth
        />
        {/* Add other fields as needed */}
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </CardContent>
    </Card>
    </Container>
  );
};

export default EditProfile;
