// NoDataCard.tsx
import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

interface NoDataCardProps {
  title: string;
}

const NoDataCard: React.FC<NoDataCardProps> = ({ title }) => {
  return (
    <Card variant="outlined" sx={{ textAlign: 'center', padding: 3 }}>
      <CardContent>
        <Typography variant="h6">{title}</Typography>
        <Typography color="textSecondary">No data available</Typography>
      </CardContent>
    </Card>
  );
};

export default NoDataCard;
