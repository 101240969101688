import { Button, Container, CssBaseline, TextField } from "@mui/material";
import TitleBanner from "../TitleBanner/TitleBanner";
import { useState } from "react";
import { getLoggedInUser } from "../../utils/util";
import { isEmpty } from 'lodash-es';
import { PasswordDTO, changePassword } from "../../API/services";
import { useNotificationStore } from "../../store/NotificationStore";
import { success, warning } from "../Notification/NotificationMessages";

interface Password{
    password:string;
    confirmPassword:string;
}
const PasswordChange=()=>{
    const getLoggedInUserEmail = getLoggedInUser();
    const [newPassword, setNewPassword] = useState<Password>({password:'',confirmPassword:''});
    const setNotification = useNotificationStore((state) => state.setNotification);

    const handleChange= async() => {
        if (isEmpty(newPassword.password) || isEmpty(newPassword.confirmPassword)) {
            setNotification(warning('Empty Password Provided'));
            return;
        }
        if(newPassword.password!=newPassword.confirmPassword)
        {
            setNotification(warning('Both passwords must be same'));
            return;
        }
        if (getLoggedInUserEmail) {
            const payload:PasswordDTO={
                email:getLoggedInUserEmail,
                password:newPassword.password
            };
            const response= await changePassword(payload);
            if(response.status=200) setNotification(success('Password Changed Successfully'));
            }
      };
    return(

    <Container component="main">
    <CssBaseline />
    <TitleBanner title={"Update Password"} />
    <TextField
          label="New Password"
          type="password"
          value={newPassword.password}
          onChange={(e) => setNewPassword((prevState)=>({  ...prevState,password:e.target.value}))}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Confirm Password"
          type="text"
          value={newPassword.confirmPassword}
          onChange={(e) =>setNewPassword((prevState)=>({  ...prevState,confirmPassword:e.target.value}))}
          fullWidth
          margin="normal"
        />
        <Button variant="contained" color="primary" onClick={handleChange}>
          Update
        </Button>
    </Container>
    );
};
export default PasswordChange;